.p-home .p-head {
  display: block;
  height: calc(100vh - 100px);
  position: relative;
}
.p-home .p-head__herocontainer {
  position: relative;
  height: calc(100% - 140px);
  display: flex;
}
html.avon .p-home .p-head__herocontainer {
  padding-top: 100px;
  height: calc(100vh - 130px);
}
@media screen and (max-width: 768px) {
  html.avon .p-home .p-head__herocontainer {
    padding-top: 0;
  }
}
.p-home .p-head__ctablocks {
  display: flex;
  flex-direction: column;
  width: 377px;
  height: 100%;
}
.p-home .p-head__cta {
  text-decoration: none;
  width: 100%;
  height: 50%;
  padding: 0 40px 30px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
}
html.non-touch .p-home .p-head__cta:hover .p-head__cta-image {
  transform: scale(1.1);
}
.p-home .p-head__slidercontaineroverlay, .p-home .p-head__cta-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #fff;
}
.p-home .p-head__cta-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: transform 0.3s;
}
.p-home .p-head__scrollicon {
  position: absolute;
  left: 50%;
  bottom: 120px;
  z-index: 10;
  margin-left: -188.5px;
  opacity: 0;
}
.p-home .p-head__videocontainer, .p-home .p-head__slidercontainer {
  flex: 1;
  height: 100%;
  margin: 0;
}
.p-home .p-head__videocontainer {
  overflow: hidden;
  position: relative;
}
.p-home .p-head__video {
  position: absolute;
  top: 0;
  left: -9999px;
  right: -9999px;
  margin: 0 auto;
  height: 100%;
  /* 1. No object-fit support: */
  /* 2. If supporting object-fit, overriding (1): */
}
@media (min-aspect-ratio: 16/9) {
  .p-home .p-head__video {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .p-home .p-head__video {
    width: 300%;
    left: -100%;
  }
}
@supports (object-fit: cover) {
  .p-home .p-head__video {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.p-home .p-head__slidercontaineroverlay {
  left: auto;
  right: 0;
  width: 100%;
}
.p-home .p-head .swiper-slide {
  overflow: hidden;
}
.p-home .p-head .swiper-wrapper {
  position: relative;
}
.p-home .p-head .swiper-button-next,
.p-home .p-head .swiper-button-prev {
  background: none;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
}
.p-home .p-head .swiper-button-prev {
  margin-top: -25px;
}
.p-home .p-head .swiper-button-next {
  margin-top: 25px;
}
.p-home .p-head .swiper-button-next .circlearrow {
  transform: scaleX(-1);
}
.p-home .p-head .slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}
.p-home .p-head__lockupimg {
  display: none !important;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 270px;
  opacity: 0;
}
@media screen and (max-width: 1160px) {
  .p-home .p-head__scrollicon {
    bottom: 10px;
  }
}
@media screen and (max-width: 800px) {
  .p-home .p-head__herocontainer {
    height: 272px;
  }
  .p-home .p-head .swiper-button-next,
  .p-home .p-head .swiper-button-prev {
    left: 25px;
  }
}
html.avon .p-home .p-head {
  margin-bottom: 70px;
  height: auto;
}
html.avon .p-home .p-head__scrollicon {
  margin-left: 0;
}
@media screen and (max-width: 800px) {
  html.avon .p-home .p-head__herocontainer {
    height: 800px;
    max-height: 80vh;
  }
  html.avon .p-home .p-head__lockupimg {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  html.avon .p-home .p-head__herocontainer {
    height: 720px;
    max-height: calc(100vh - 230px);
  }
}
@media screen and (max-width: 600px) {
  html.avon .p-home .p-head__herocontainer {
    height: 720px;
    max-height: calc(100vh - 180px);
  }
}
@media (max-height: 800px) {
  html.avon .p-home .p-head .swiper-button-prev,
  html.avon .p-home .p-head .swiper-button-next {
    transform: translateY(calc(-50% - 120px));
  }
}
@media (max-height: 800px) {
  html.avon-us .p-home .p-head .swiper-button-prev,
  html.avon-us .p-home .p-head .swiper-button-next {
    transform: translateY(calc(-50% - 40px));
  }
}
@media screen and (max-width: 800px) {
  html.cooper .p-home .p-head, html.avon .p-home .p-head {
    height: auto;
    margin-bottom: 50px;
  }
  html.cooper .p-home .p-head__ctablocks, html.cooper .p-home .p-head__scrollicon, html.avon .p-home .p-head__ctablocks, html.avon .p-home .p-head__scrollicon {
    display: none;
  }
}
@media (min-height: 1120px) and (min-width: 1000px) {
  .p-home .p-head__ctablocks {
    width: 40vh;
  }
}
.p-home .p-mobilecta {
  display: none !important;
}
@media screen and (max-width: 800px) {
  .p-home .p-mobilecta {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 0 24px 28px;
    text-decoration: none;
  }
  .p-home .p-mobilecta .circlearrow__hoverelement {
    display: inline-flex;
  }
  .p-home .p-mobilecta__title {
    font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 31px;
    width: 250px;
    max-width: 90vw;
    margin: 0 0 24px;
  }
}
.p-home .p-mobilectablocks {
  display: none;
  padding: 24px 0 0;
  background: #F8F8F8;
}
.p-home .p-mobilectablocks__title {
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 0.01px;
  margin: 0 0 16px;
  padding: 0 24px;
  -webkit-overflow-scrolling: touch;
}
html.cooper .p-home .p-mobilectablocks__title {
  color: #001d60;
}
html.avon .p-home .p-mobilectablocks__title {
  color: #000;
}
html.cooper .p-home .p-mobilectablocks__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-home .p-mobilectablocks__title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.p-home .p-mobilectablocks__scrollcontainer {
  overflow-x: scroll;
}
.p-home .p-mobilectablocks__blocks {
  position: relative;
  height: 280px;
  width: calc(100% + 64px);
}
.p-home .p-mobilectablocks__block {
  background: #FFFFFF;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  width: 185px;
  position: absolute;
  top: 0;
  left: 24px;
}
.p-home .p-mobilectablocks__block:nth-child(1) .p-mobilectablocks__text-title {
  position: relative;
  top: 2px;
}
.p-home .p-mobilectablocks__block:nth-child(2) {
  left: 224px;
}
.p-home .p-mobilectablocks__block-image {
  width: 100%;
  height: 124px;
  background: red;
}
.p-home .p-mobilectablocks__text-container {
  display: flex;
  height: 54px;
  align-items: center;
  padding: 0 28px;
}
.p-home .p-mobilectablocks__text-icon {
  margin-right: 12px;
}
.p-home .p-mobilectablocks__text-icon svg {
  display: block;
}
.p-home .p-mobilectablocks__text-title {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01px;
}
html.cooper .p-home .p-mobilectablocks__text-title {
  color: #001d60;
}
html.avon .p-home .p-mobilectablocks__text-title {
  color: #000;
}
html.cooper .p-home .p-mobilectablocks__text-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-home .p-mobilectablocks__text-title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.p-home .p-mobilectablocks__scroller {
  position: absolute;
  bottom: 60px;
  left: 24px;
  width: calc(100vw - 24px);
  background: #fff;
  height: 2px;
}
.p-home .p-mobilectablocks__scroller svg {
  position: absolute;
  top: -12px;
  left: 76px;
}
@media screen and (max-width: 800px) {
  .p-home .p-mobilectablocks {
    display: block;
  }
}
.p-home .p-campaign {
  position: relative;
}
html.non-touch .p-home .p-campaign__cta-container:hover .p-campaign__cta-image {
  transform: scale(1.1);
}
.p-home .p-campaign__infoinnercontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
html.cooper .p-home .p-campaign__infocontainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 140px;
  opacity: 0;
}
html.cooper .p-home .p-campaign__date {
  font-size: 18px;
  line-height: 100%;
  color: #001d60;
  letter-spacing: 3.86px;
  font-weight: 700;
}
html.cooper .p-home .p-campaign__divider {
  font-size: 18px;
  line-height: 100%;
  color: #EB0328;
  margin: 0 34px;
}
html.cooper .p-home .p-campaign__title {
  font-size: 18px;
  line-height: 100%;
  text-decoration: none;
  color: #001d60;
  letter-spacing: 1px;
  text-transform: uppercase;
}
html.non-touch html.cooper .p-home .p-campaign__title:hover {
  text-decoration: underline;
}
html.cooper .p-home .p-campaign__cta-container {
  position: absolute;
  bottom: 0;
  display: flex;
  z-index: 20;
  opacity: 0;
  text-decoration: none;
}
html[dir=ltr] html.cooper .p-home .p-campaign__cta-container {
  left: 0;
}
html[dir=rtl] html.cooper .p-home .p-campaign__cta-container {
  right: 0;
}
html.cooper .p-home .p-campaign__cta-image-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: #000;
  width: 240px;
  min-height: 240px;
  position: relative;
  overflow: hidden;
}
html.cooper .p-home .p-campaign__cta-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.3s;
}
html.cooper .p-home .p-campaign__cta-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 40px;
  color: #001d60;
  padding: 40px 120px 40px 40px;
  background: #fff;
  letter-spacing: 1px;
  max-width: 435px;
}
@media screen and (max-width: 1380px) {
  html.cooper .p-home .p-campaign__cta-title {
    padding-right: 40px;
    max-height: 355px;
  }
}
@media screen and (max-width: 1160px) {
  html.cooper .p-home .p-campaign__cta-container, html.cooper .p-home .p-campaign__cta-image-container, html.cooper .p-home .p-campaign__cta-title {
    height: 100%;
  }
  html.cooper .p-home .p-campaign__infocontainer {
    padding-right: 15px;
  }
  html.cooper .p-home .p-campaign__cta-image-container {
    min-height: initial;
    width: 136px;
  }
  html.cooper .p-home .p-campaign__cta-title {
    font-size: 30px;
    line-height: 26px;
    max-width: 335px;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 1000px) {
  html.cooper .p-home .p-campaign__date, html.cooper .p-home .p-campaign__divider {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  html.cooper .p-home .p-campaign {
    display: none;
  }
}
html.cooper .p-home .p-campaign__circlearrow {
  margin: 0 220px 0 100px;
}
@media screen and (max-width: 1800px) {
  html.cooper .p-home .p-campaign__circlearrow {
    margin-right: 0;
  }
}
@media screen and (max-width: 1380px) {
  html.cooper .p-home .p-campaign__circlearrow {
    margin-left: 34px;
  }
}
html.cooper[dir=rtl] .p-home .p-campaign__cta-container {
  left: auto;
  right: 0;
}
html.cooper[dir=rtl] .p-home .p-campaign__circlearrow {
  margin-left: 220px;
  margin-right: 100px;
}
@media screen and (max-width: 1800px) {
  html.cooper[dir=rtl] .p-home .p-campaign__circlearrow {
    margin-left: 0;
  }
}
@media screen and (max-width: 1380px) {
  html.cooper[dir=rtl] .p-home .p-campaign__circlearrow {
    margin-right: 34px;
  }
}
@media screen and (max-width: 1160px) {
  html.cooper[dir=rtl] .p-home .p-campaign__infocontainer {
    padding-left: 15px;
    padding-right: 0;
  }
}
html[dir=rtl] .p-home .p-campaign__cta-title {
  padding: 40px 40px 40px 120px;
}
html.avon .p-home .p-campaign {
  margin: 160px 0 0;
  display: flex;
  justify-content: center;
}
html.avon .p-home .p-campaign__cta-container, html.avon .p-home .p-campaign__circlearrow {
  display: none;
}
html.avon .p-home .p-campaign__infocontainer {
  opacity: 0;
  display: inline-flex;
  margin: 0 auto;
  border: 1px solid #d2d2d2;
  padding: 30px 75px;
}
html.avon .p-home .p-campaign__divider {
  font-size: 18px;
  line-height: 100%;
  color: #EB0328;
  margin: 0 24px;
}
html.avon .p-home .p-campaign__date, html.avon .p-home .p-campaign__title {
  font-size: 18px;
  line-height: 100%;
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #000;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
html.avon .p-home .p-campaign__title {
  text-decoration: none;
}
html.avon .p-home .p-campaign__title:hover {
  text-decoration: underline;
}
@media screen and (max-width: 600px) {
  html.avon .p-home .p-campaign {
    margin-top: 110px;
  }
  html.avon .p-home .p-campaign__infocontainer {
    padding: 15px;
  }
  html.avon .p-home .p-campaign__date, html.avon .p-home .p-campaign__title {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.23px;
  }
}
@media screen and (max-width: 400px) {
  html.avon .p-home .p-campaign__date, html.avon .p-home .p-campaign__title {
    text-align: center;
  }
  html.avon .p-home .p-campaign__infoinnercontainer {
    flex-direction: column;
  }
  html.avon .p-home .p-campaign__divider {
    margin: 15px 0;
  }
}
html.avon .p-home .embeddedobject--carousel {
  position: relative;
}
html.avon .p-home .embeddedobject--carousel:before {
  content: "";
  display: block;
  z-index: -1;
  position: absolute;
  background-color: #f4f5f7;
  left: 0;
  right: -230px;
  bottom: 100%;
  height: 400px;
  transform: skew(-40deg);
}
.p-home .c-avonctas {
  position: absolute;
  left: 50%;
  bottom: -120px;
  transform: translateX(-50%);
  z-index: 10;
  opacity: 0;
  width: 1170px;
  max-width: calc(100vw - 24px);
}
.p-home .c-avonctas__title {
  font-size: 40px;
  line-height: 100%;
  color: #fff;
  letter-spacing: 0.64px;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  margin: 0 0 25px;
  text-transform: uppercase;
}
html.cooper .p-home .c-avonctas__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-home .c-avonctas__title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.p-home .c-avonctas__blocks {
  display: flex;
  box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  background: #fff;
}
.p-home .c-avonctas__block {
  position: relative;
  width: 25%;
  height: 240px;
  transition: background 0.3s;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.p-home .c-avonctas__block:before, .p-home .c-avonctas__block:after {
  content: "";
  position: absolute;
}
.p-home .c-avonctas__block:before {
  top: 0;
  left: 0;
  width: 0%;
  transition: width 0.3s;
  height: 4px;
  background: #ED0000;
}
.p-home .c-avonctas__block:after {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  background: #B4B4B4;
  height: 90px;
}
.p-home .c-avonctas__block:last-child:after {
  display: none;
}
html.non-touch .p-home .c-avonctas__block:hover {
  background: #F4F5F7;
}
html.non-touch .p-home .c-avonctas__block:hover:before {
  width: 100%;
}
.p-home .c-avonctas__block-icon {
  margin: 0 0 15px;
  width: 124px;
  height: auto;
}
.p-home .c-avonctas__block-icon--car {
  margin-top: 25px;
}
.p-home .c-avonctas__block-icon--van {
  margin-top: 17px;
}
.p-home .c-avonctas__block-icon--motorcycle {
  margin-top: 12px;
}
.p-home .c-avonctas__block-icon--motorsport {
  margin-top: 41px;
}
html.avon-us .p-home .c-avonctas__block-icon--motorsport {
  margin-top: 11px;
}
.p-home .c-avonctas__block-title {
  font-size: 16px;
  line-height: 100%;
  color: #000000;
  text-transform: uppercase;
}
html.cooper .p-home .c-avonctas__block-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-home .c-avonctas__block-title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
html.avon-us .p-home .c-avonctas__block {
  width: 50%;
}
@media screen and (max-width: 1170px) {
  .p-home .c-avonctas {
    transform: translateX(-50%) !important;
  }
}
@media screen and (max-width: 800px) {
  .p-home .c-avonctas__title {
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 15px;
  }
  .p-home .c-avonctas__blocks {
    flex-flow: row wrap;
  }
  .p-home .c-avonctas__block {
    width: 50%;
    height: 140px;
  }
  .p-home .c-avonctas__block:after {
    display: none;
  }
  .p-home .c-avonctas__block-icon {
    width: 100px;
  }
  .p-home .c-avonctas__block-icon--motorsport {
    margin-top: 34px;
  }
}
@media screen and (max-width: 600px) {
  .p-home .c-avonctas {
    bottom: -70px;
  }
}

html.page-home .header {
  opacity: 0;
}