.embeddedobject--socialmedia {
  margin-top: 0;
}

.socialmedia__cols {
  display: flex;
  justify-content: space-between;
}
.socialmedia__col--left {
  width: 670px;
}
@media (max-width: 1200px) {
  .socialmedia__col--left {
    width: calc(50% - 15px);
  }
}
.socialmedia__col--right {
  width: 470px;
}
@media (max-width: 1200px) {
  .socialmedia__col--right {
    width: calc(50% - 15px);
  }
}
.socialmedia__bigitem-container {
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.socialmedia__bigimage-container, .socialmedia__smallitem-image-container {
  position: relative;
  overflow: hidden;
}
.socialmedia__bigimage-container.youtube .socialmedia__smallitem-image, .socialmedia__smallitem-image-container.youtube .socialmedia__smallitem-image {
  transform: scale(1.35);
}
.socialmedia__bigimage, .socialmedia__smallitem-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}
.socialmedia__bigimage-container {
  height: 338px;
}
.socialmedia__bigitem {
  width: 100%;
  height: 162px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.socialmedia__bigitem-text {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 56px; /* Fallback for non-webkit */
  font-size: 18px !important;
  line-height: 1.5555555556 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.socialmedia__bigitem-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.socialmedia__smallitems {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.socialmedia__smallitem {
  height: 180px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  margin: 0 0 13px;
  text-decoration: none;
}
.socialmedia__smallitem:last-child {
  margin-bottom: 0;
}
.socialmedia__smallitem-text {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 84px; /* Fallback for non-webkit */
  font-size: 18px !important;
  line-height: 1.5555555556 !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.socialmedia__smallitem-image-container {
  flex: 1;
}
.socialmedia__smallitem-info {
  width: 300px;
  padding: 21px 38px 18px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.socialmedia__smallitem-link-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.socialmedia__circlearrowcontainer {
  display: block;
}
.socialmedia__circlearrowcontainer .circlearrow {
  display: block;
}
.socialmedia__bigitem-network {
  padding-right: 16px;
}
.socialmedia__smallitem-network svg {
  display: block;
}
.socialmedia__hovertext {
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 1.33px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
html.cooper .socialmedia__hovertext {
  color: #001d60;
}
html.avon .socialmedia__hovertext {
  color: #000;
}
html.cooper .socialmedia__hovertext {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .socialmedia__hovertext {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.socialmedia__networks-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
}
.socialmedia__networks {
  display: flex;
  align-items: center;
}
.socialmedia__network {
  margin: 0 35px 0 0;
}
.socialmedia__network svg {
  display: block;
  height: 32px;
}
html[dir=rtl] .socialmedia__network {
  margin-left: 35px;
  margin-right: 0;
}
.socialmedia__networks-divider {
  width: 56px;
  height: 100%;
  position: relative;
}
.socialmedia__networks-divider:after {
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
html.cooper .socialmedia__networks-divider:after {
  background-color: #001d60;
}
html.avon .socialmedia__networks-divider:after {
  background-color: #000;
}
html.avon .socialmedia__hovertext {
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 1.22px;
}
html.avon .socialmedia__network {
  margin-right: 30px;
}
html.avon .socialmedia__networks-divider {
  width: 40px;
}
html.avon .socialmedia__bigitem-text {
  font-size: 16px;
  line-height: 25px;
}
html.avon .socialmedia svg .svg-instagram2,
html.avon .socialmedia svg .svg-linkedin,
html.avon .socialmedia svg .svg-facebook,
html.avon .socialmedia svg .svg-youtube2 {
  fill: #000;
}
html.avon[dir=rtl] .socialmedia__network {
  margin-left: 30px;
  margin-right: 0;
}
@media screen and (max-width: 950px) {
  .socialmedia__networks-divider, .socialmedia__hovertext {
    display: none;
  }
}
@media screen and (max-width: 850px) {
  .socialmedia__cols {
    flex-direction: column;
    position: relative;
    padding-top: 50px;
  }
  .socialmedia__col {
    width: 100%;
  }
  .socialmedia__bigitem, .socialmedia__smallitem-info {
    width: 400px;
  }
  .socialmedia__bigitem-container, .socialmedia__smallitem {
    margin: 0 0 20px;
  }
  .socialmedia__bigitem, .socialmedia__smallitem-info {
    padding: 25px;
  }
  .socialmedia__bigitem-container {
    height: 180px;
    flex-direction: row;
  }
  .socialmedia__bigimage-container {
    flex: 1;
    height: 100%;
  }
  .socialmedia__bigimage-container.youtube .socialmedia__bigimage {
    transform: scale(1.15);
  }
  .socialmedia__bigitem-network {
    display: block;
  }
  .socialmedia__networks-container {
    position: absolute;
    top: 0;
    left: 13px;
    right: 13px;
  }
  .socialmedia__network {
    margin-right: 18px;
  }
  .socialmedia__network svg {
    height: 17px;
  }
}
@media screen and (max-width: 580px) {
  .socialmedia__bigitem-container, .socialmedia__smallitem {
    display: block;
  }
  .socialmedia__bigimage-container, .socialmedia__smallitem-image-container {
    flex: auto;
    height: 200px;
  }
  .socialmedia__bigitem-container, .socialmedia__smallitems, .socialmedia__smallitem {
    height: auto;
  }
  .socialmedia__bigitem, .socialmedia__smallitem-info {
    width: 100%;
  }
  .socialmedia__bigitem-network, .socialmedia__smallitem-network {
    display: none;
  }
  .socialmedia__bigitem-link-container, .socialmedia__smallitem-link-row {
    justify-content: flex-end;
  }
  .socialmedia__bigitem, .socialmedia__smallitem-info {
    padding: 25px 15px 5px;
  }
}