.header {
  position: relative;
  width: 100vw;
  height: 100px;
  z-index: 500;
}
.header__cartypelink {
  text-decoration: none;
  display: flex;
  border-bottom: 2px solid transparent;
  transition: border 0.3s;
  align-items: flex-end;
  padding-bottom: 7px;
}
.header__cartypelink:hover, .header__cartypelink.selected {
  border-color: #EB0328;
}
.header__cartypelink:hover .header__cartypeimg, .header__cartypelink.selected .header__cartypeimg {
  opacity: 1;
}
.header__cartypeimg {
  display: block;
  opacity: 0.4;
  transition: opacity 0.3s;
}
.header__cartypeimg--car {
  height: 18px;
}
.header__cartypeimg--4x4 {
  height: 20px;
}
.header__cartypeimg--van {
  height: 20px;
}
.header__cartypeimg--suv {
  height: 16px;
}
html[dir=rtl] .header__cartypeimg {
  transform: scaleX(-1);
}
.header__bottom-bar, .header__bottom-bar-left, .header__bottom-bar-right {
  position: absolute;
  background: #fff;
  height: 100px;
}
.header__bottom-bar {
  left: 50%;
  transform: translateX(-50%);
  width: 1170px;
  display: flex;
  align-items: center;
  z-index: 10;
  position: fixed !important;
  top: 0 !important;
}
@media (max-width: 1024px) {
  .header__bottom-bar {
    transform: translateX(-50%);
  }
}
.header__bottom-bar-logo {
  display: none;
  margin-right: 75px;
}
html[dir=rtl] .header__bottom-bar-logo {
  margin-left: 75px;
  margin-right: 0;
}
html.cooper .header__bottom-bar-logo--cooper {
  display: inline-flex;
  align-items: center;
}
html.avon .header__bottom-bar-logo--avon {
  display: inline-flex;
  align-items: center;
}
.header__bottom-bar-logo > img {
  display: block;
}
.header__bottom-bar-contents {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.header__bottom-bar-rightpart {
  display: flex;
  align-items: center;
  position: relative;
}
.header__bottom-bar-logo-main-links {
  display: flex;
}
.header__bottom-bar-main-link, .header__bottom-bar-right-link {
  font-size: 24px;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  margin-right: 3.125rem;
  white-space: nowrap;
  transition: border 0.1s;
  padding-top: 4px;
  text-decoration: none;
  position: relative;
}
html.cooper .header__bottom-bar-main-link, html.cooper .header__bottom-bar-right-link {
  color: #001d60;
}
html.avon .header__bottom-bar-main-link, html.avon .header__bottom-bar-right-link {
  color: #000;
}
.header__bottom-bar-main-link:after, .header__bottom-bar-right-link:after {
  content: "";
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 0;
  height: 3px;
  display: block;
  background: #EB0328;
  transition: width 0.3s;
}
html.non-touch .header__bottom-bar-main-link:hover, html.non-touch .header__bottom-bar-right-link:hover {
  cursor: pointer;
}
html.non-touch .header__bottom-bar-main-link:hover:after, html.non-touch .header__bottom-bar-right-link:hover:after {
  width: 100%;
}
.header__bottom-bar-main-link.selected:after, .header__bottom-bar-main-link--selected:after, .header__bottom-bar-right-link.selected:after, .header__bottom-bar-right-link--selected:after {
  width: 100%;
}
.header__bottom-bar-main-link > *, .header__bottom-bar-right-link > * {
  pointer-events: none;
}
.header__bottom-bar-main-link > span, .header__bottom-bar-right-link > span {
  margin-left: 0.5rem;
}
html[dir=rtl] .header__bottom-bar-main-link > span, html[dir=rtl] .header__bottom-bar-right-link > span {
  margin-left: 0;
  margin-right: 0.5rem;
}
html.avon .header__bottom-bar-main-link, html.avon .header__bottom-bar-right-link {
  font-size: 24px;
  line-height: 100%;
  font-weight: 700;
  margin-right: 1.875rem;
}
html.avon .header__bottom-bar-main-link > span, html.avon .header__bottom-bar-right-link > span {
  margin-left: 0.9375rem;
}
.header__bottom-bar-right-link {
  font-weight: 400;
  margin-right: 0;
}
.header__bottom-bar-right-link:after {
  top: 100%;
}
.header__bottom-bar-search-icon {
  display: flex;
  align-items: center;
  margin-left: 4rem;
}
html.avon .header__bottom-bar-search-icon {
  margin-left: 2.5rem;
}
.header__searchform {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  position: absolute;
  right: -40px;
  top: -32px;
  transition: width 0.3s, background 0.3s;
  width: 90px;
}
html.header-search-active .header__searchform {
  background: #F4F5F7;
}
html[dir=rtl] .header__searchform {
  right: auto;
  left: 0;
}
.header__searchinput {
  width: 100%;
  height: 56px;
  border: 1px solid transparent;
  transition: width 0.3s;
  outline: none;
  padding: 0;
  font-size: 0;
}
html.cooper .header__searchinput {
  color: #001d60;
}
html.avon .header__searchinput {
  color: #000;
}
.header__searchicon {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  outline: none;
  height: 22px;
  transition: background 0.3s;
  position: static;
  display: block;
  top: 0;
  transform: none;
}
.header__bottom-bar-left-link-container {
  position: relative;
}
.header__bottom-bar-right-link-container {
  margin-left: 3.125rem;
  display: inline-block;
  position: relative;
}
html[dir=rtl] .header__bottom-bar-right-link-container {
  margin-left: 0;
  margin-right: 3.125rem;
}
.header__bottom-bar-right-link {
  font-size: 18px;
  line-height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  padding-bottom: 4px;
  transition: border 0.1s;
}
html.cooper .header__bottom-bar-right-link {
  color: #001d60;
}
html.avon .header__bottom-bar-right-link {
  color: #000;
}
.header__bottom-bar-right-link--selected, .header__bottom-bar-right-link:hover {
  text-decoration: none;
  border-color: #001d60;
}
.header__bottom-bar-right-link--last {
  margin-right: 4.375rem;
}
html[dir=rtl] .header__bottom-bar-right-link--last {
  margin-left: 4.375rem;
  margin-right: 0;
}
html.avon .header__bottom-bar-right-link {
  margin-left: 2.5rem;
  font-weight: 700;
}
.header__bottom-bar-subitems-container {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 45px;
  transition: opacity 0.3s;
  z-index: -1;
}
.header__bottom-bar-subitems-container.show {
  opacity: 1;
  z-index: 0;
  pointer-events: auto;
}
.header__bottom-bar-subitems-topimg {
  content: "";
  position: absolute;
  top: 23px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 22px;
  border-color: transparent transparent #fff;
  z-index: 9999;
}
.header__bottom-bar-subitems {
  min-width: 200px;
  background: #fff;
  padding: 40px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}
.header__bottom-bar-subitemlink {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 100%;
  display: block;
  text-decoration: none;
  font-weight: 700;
  padding: 6px 80px 6px 0;
  white-space: nowrap;
  position: relative;
}
html[dir=rtl] .header__bottom-bar-subitemlink {
  padding-left: 80px;
  padding-right: 0;
}
html.cooper .header__bottom-bar-subitemlink.selected, html.cooper .header__bottom-bar-subitemlink:hover {
  color: #001d60;
}
html.avon .header__bottom-bar-subitemlink.selected, html.avon .header__bottom-bar-subitemlink:hover {
  color: #000;
}
.header__bottom-bar-subitemlink.selected .header__bottom-bar-subitemlink-gt .svg-gt-submenu-stroke, .header__bottom-bar-subitemlink:hover .header__bottom-bar-subitemlink-gt .svg-gt-submenu-stroke {
  stroke: #EB0328;
}
.header__bottom-bar-subitemlink-gt {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
html[dir=rtl] .header__bottom-bar-subitemlink-gt {
  right: auto;
  left: 0;
  transform: translateY(-50%) scaleX(-1);
}
.header__bottom-bar-subitemlink-gt svg {
  display: block;
}
.header__bottom-bar-subitemlink-gt .svg-gt-submenu-stroke {
  transition: all 0.3s;
}
html.avon .header__bottom-bar-rightpart {
  padding-right: 30px;
  top: -5px;
}
html.avon .header__searchform {
  top: -27px;
  width: 80px;
}
html.cooper.isie .header, html.avon.isie .header {
  overflow-x: visible;
}
html.cooper.isie .header__bottom-bar-left, html.cooper.isie .header__bottom-bar-right, html.avon.isie .header__bottom-bar-left, html.avon.isie .header__bottom-bar-right {
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
html.cooper .header__cartypelink {
  margin: 0 27px 0 0;
}
html.cooper .header__bottom-bar {
  width: 100vw;
  left: 0;
  transform: none;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
}
html.cooper .header__bottom-bar-left, html.cooper .header__bottom-bar-right, html.cooper .header__bottom-bar-shadow {
  display: none;
}
html.cooper .header__bottom-bar-contents {
  width: 1170px;
  margin: 0 auto;
}
html.cooper .header__bottom-bar-left, html.cooper .header__bottom-bar-right {
  width: 100vw;
  z-index: -1;
}
html.cooper .header__bottom-bar-left {
  left: -100vw;
}
html.cooper .header__bottom-bar-shadow {
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  height: 3px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
}
html.cooper[dir=rtl] .header .header__cartypelink {
  margin-left: 27px;
  margin-right: 0;
}
html.avon .header {
  height: 100px;
  overflow-x: visible;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 4px 2px 4px 0 rgba(0, 0, 0, 0.1);
}
html.avon .header:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 1;
  content: "";
}
html.avon .header__bottom-bar {
  top: 0;
  z-index: 1;
}
@media (max-width: 1024px) {
  html.avon .header__bottom-bar {
    position: absolute;
    transform: translateX(-50%);
  }
}
html.avon .header__bg-left-pos, html.avon .header__bg-left {
  position: absolute;
  top: 0;
  z-index: 2;
  pointer-events: none;
}
html.avon .header__bg-left-part-avon {
  transform: translate(30px, -30px);
  position: fixed;
  top: 0;
  right: calc(100% + 39px);
  width: 252px;
  height: 187px;
}
html.avon .header__bg-left-part-avon:after {
  content: "";
  position: absolute;
  top: 0;
  right: 250px;
  bottom: 0;
  background: #F4F5F7;
  width: 600px;
}
html.avon .header__bg-left-part-avon svg {
  position: absolute;
  top: 0;
  right: 0;
}
html.avon .header__bg-left-pos {
  width: 1170px;
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
}
html.avon .header__bg-left {
  display: none;
}
html.avon .header__cartypeimg {
  transform: skew(40deg);
  height: auto;
  position: relative;
}
html.avon .header__cartypeimg--car {
  top: 4px;
}
html.avon .header__cartypeimg--motorcycle {
  height: 34px;
  top: -3px;
}
html.avon .header__cartypeimg--motorsport {
  height: 23px;
  top: 3px;
}
html.avon .header__cartypelink, html.avon .header__top-bar-link {
  font-size: 14px;
  line-height: 100%;
}
html.avon .header__cartypelink {
  display: inline-flex;
  width: 125px;
  height: 0;
  transform: skew(-40deg);
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
  border-bottom: 0 none;
  padding-bottom: 0;
}
html.avon .header__cartypelink:nth-child(even) {
  background: #fff;
}
html.avon .header__cartypelink--selected, html.avon .header__cartypelink--selected:nth-child(even), html.avon .header__cartypelink:hover {
  background: #ED0000;
  border-bottom: 0 none;
}
html.avon .header__cartypelink .link {
  display: inline-block;
  transform: skew(40deg);
}
html.avon .header__skewedblocks-row-2 {
  transform: translate(-61px, -1px);
}
html.avon .header__skewedblocks-row-3 {
  transform: translate(2px, 0);
}
html.avon .header__hide-skewedblock {
  visibility: hidden;
}
html.avon .header__bottom-bar-left {
  right: -45px;
  width: 100vw;
  z-index: -1;
  top: 0;
  height: 100px;
  transform: skew(-40deg) translateX(-1166px);
  background: #fff;
}
html.avon .header__bottom-bar-right {
  top: 0;
  left: 100%;
  width: 100vw;
  z-index: -1;
}
html.avon .header__bottom-bar-shadow {
  position: absolute;
  top: 0;
  left: -40px;
  right: -100vw;
  height: 7px;
  box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.1);
}
html.avon .header__bottom-bar {
  padding-top: 10px;
}
html.avon .header__bottom-bar-right-link {
  font-size: 16px;
  font-family: "ITC Avant Garde Gothic W01BkCn";
  margin-left: 1.5rem;
}
html.avon .header__bottom-bar-right-link-container {
  margin-left: 0;
}
html.avon .header__bottom-bar-subitemlink {
  font-family: "ITC Avant Garde Gothic";
  font-weight: 300;
  font-size: 18px;
  text-transform: none;
}
html.avon .header__bottom-bar-subitemlink:hover, html.avon .header__bottom-bar-subitemlink--selected {
  color: #ED0000;
}
html.avon .header__searchclose > svg .svg-modal-close {
  stroke: #000;
}
html.avon.avon-us .header__cartypeimg--motorsport {
  height: 31px;
  top: -1px;
}
html.avon.fixedheader .header__bottom-bar-left {
  width: 100vw;
}
@media screen and (max-width: 1200px) {
  .header__searchform {
    right: 0;
    width: 50px;
  }
}
@media (max-width: 1024px) {
  .header__bottom-bar-main-link {
    font-size: 20px;
    line-height: 100%;
    margin-right: 1.875rem;
  }
  .header__bottom-bar-right-link-container {
    margin-left: 0rem;
  }
  .header__bottom-bar-right-link {
    margin-left: 1.5625rem;
    @Include font-size(16);
  }
  .header__bottom-bar {
    transform: none;
    width: 100vw;
    position: static;
    padding: 0 25px;
  }
  .header__bottom-bar-logo {
    margin-right: 40px;
  }
  html.cooper .header__bottom-bar-left, html.cooper .header__bottom-bar-right, html.cooper .header__top-bar-topleft {
    display: none;
  }
}
@media (max-width: 768px) {
  .header {
    display: none;
  }
}

.header .c-search {
  position: fixed;
  left: 0;
  top: 100px;
  right: 0;
  background: #F4F5F7;
  transition: transform 0.3s;
  transform: translateY(-100%);
  display: block !important;
  box-shadow: 4px 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.header .c-search__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 120px;
  padding-left: 379px;
}
.header .c-search__icon {
  position: relative;
  top: 4px;
  margin: 0 26px 0 0;
}
.header .c-search__input {
  width: 560px;
  height: 46px;
  box-shadow: none;
  background: transparent;
  border: 0;
  outline: none;
}
.header .c-search__input, .header .c-search__input::placeholder {
  font-size: 32px;
  line-height: 100%;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #9B9B9B;
}
.header .c-search__input::placeholder {
  position: relative;
  left: 10px;
  margin-left: 10px;
}
html.avon .header .c-search__icon .svg-search-blue {
  fill: #ED0000;
}
@media (max-width: 1024px) {
  .header .c-search__container {
    padding-left: 371px;
  }
}
html.header-search-active .header .c-search {
  transform: translateY(0);
}

html.avon .hamburger-inner, html.avon .hamburger-inner:after, html.avon .hamburger-inner:before {
  background-color: #000;
}

.wh-anchor {
  scroll-margin-top: 115px;
}