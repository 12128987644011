.emb-video {
  height: 0;
  overflow: hidden;
  position: relative;
}
.emb-video__video {
  position: absolute;
  top: 0;
  left: -9999px;
  right: -9999px;
  margin: 0 auto;
  height: 100%;
  /* 1. No object-fit support: */
  /* 2. If supporting object-fit, overriding (1): */
}
@media (min-aspect-ratio: 16/9) {
  .emb-video__video {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .emb-video__video {
    width: 300%;
    left: -100%;
  }
}
@supports (object-fit: cover) {
  .emb-video__video {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}