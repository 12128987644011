.p-tireoverview .pageheader {
  margin-bottom: 0;
}
.p-tireoverview .p-searchbar .contentwrapper {
  display: flex;
  justify-content: space-between;
}
.p-tireoverview .p-searchbar .wh-form {
  margin-bottom: 0;
}
.p-tireoverview .p-searchbar .wh-form__fieldgroup--pulldown {
  width: 32%;
}
.p-tireoverview .p-searchbar .p-downloadrelease,
.p-tireoverview .p-mobilefilter .p-downloadrelease {
  box-shadow: none;
  display: block;
  line-height: auto;
  margin: 50px 0 30px 30px;
  text-decoration: none;
  height: auto;
  text-transform: none;
  padding-left: 0;
}
html.cooper .p-tireoverview .p-searchbar .p-downloadrelease,
html.cooper .p-tireoverview .p-mobilefilter .p-downloadrelease {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  background: none;
  color: #001d60;
}
html.avon .p-tireoverview .p-searchbar .p-downloadrelease,
html.avon .p-tireoverview .p-mobilefilter .p-downloadrelease {
  font-size: 16px;
  line-height: 30px;
  background: none;
  color: #000;
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.p-tireoverview .p-searchbar .p-downloadrelease svg,
.p-tireoverview .p-mobilefilter .p-downloadrelease svg {
  display: inline-block;
  height: 20px;
  margin-right: 20px;
  position: relative;
  top: -2px;
  vertical-align: middle;
  width: 20px;
}
.p-tireoverview .p-pagetitlecontainer {
  display: flex;
  height: 88px;
  align-items: center;
}
.p-tireoverview .p-path {
  width: 33%;
}
.p-tireoverview .p-pagetitle {
  font-weight: 700;
}
html.cooper .p-tireoverview .p-pagetitle {
  color: #001d60;
}
html.avon .p-tireoverview .p-pagetitle {
  color: #000;
}
html.avon .p-tireoverview .p-pagetitle {
  position: relative;
  transform: skew(40deg);
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 150px;
  height: 100%;
  display: flex;
  align-items: center;
}
.p-tireoverview .p-maincontents {
  display: flex;
  align-items: flex-start;
}
html.avon .p-tireoverview .p-maincontents {
  position: relative;
}
.p-tireoverview .p-mobilefilter {
  display: none;
}
.p-tireoverview .p-sidebar {
  width: 25%;
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  margin: 0 30px 0 0;
  padding: 35px;
}
.p-tireoverview .p-sidebar__close,
.p-tireoverview .p-sidebar .p-searchblock--carsearch, .p-tireoverview .p-sidebar__apply {
  display: none;
}
html[dir=rtl] .p-tireoverview .p-sidebar {
  margin-left: 30px;
  margin-right: 0;
}
.p-tireoverview .p-searchresults {
  flex: 1;
}
.p-tireoverview .p-searchresult {
  margin: 0 0 20px;
  padding-bottom: 2.2rem;
}
.p-tireoverview .p-searchresult:last-child {
  margin-bottom: 100px;
}
.p-tireoverview .p-searchblock + .p-searchblock {
  padding-top: 20px;
}
.p-tireoverview .p-searchblock .wh-form__label {
  margin-bottom: 0;
}
html.avon .p-tireoverview .p-searchblock {
  padding-bottom: 20px;
  position: relative;
}
html.avon .p-tireoverview .p-searchblock:empty {
  display: none;
}
html.avon .p-tireoverview .p-searchblock + .p-searchblock:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: -35px;
  top: 0;
  border-top: 1px solid #E7E9ED;
}
.p-tireoverview .p-searchblock:empty {
  display: none;
}
.p-tireoverview .p-searchblock + .p-searchblock {
  border-top: 1px solid #F4F5F7;
  padding: 20px 0 0;
  margin: 20px 0 0;
}
.p-tireoverview .p-resultinfo {
  background: #F4F5F7;
  display: flex;
  justify-content: space-between;
  min-height: 310px;
  position: relative;
}
.p-tireoverview .p-resultimage-container,
.p-tireoverview .p-resulttext {
  width: calc(50% - 15px);
}
.p-tireoverview .p-resultimage-container {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
.p-tireoverview .p-resultimagelink {
  overflow: hidden;
  width: 408px;
  height: 282px;
  display: block;
}
.p-tireoverview .p-resultimage {
  width: 100%;
  display: block;
}
.p-tireoverview .p-resulttitle {
  font-size: 36px;
  line-height: 100%;
  margin: 2.125rem 0 0;
}
html.cooper .p-tireoverview .p-resulttitle {
  color: #001d60;
}
html.avon .p-tireoverview .p-resulttitle {
  color: #000;
}
html.cooper .p-tireoverview .p-resulttitle {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-tireoverview .p-resulttitle {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.p-tireoverview .p-resultdescr {
  padding-right: 1.25rem;
}
html[dir=rtl] .p-tireoverview .p-resultdescr {
  padding-left: 1.25rem;
  padding-right: 0;
}
.p-tireoverview .p-resultdescr,
.p-tireoverview .p-resultdescr p:first-child {
  margin-top: 0;
}
.p-tireoverview .p-resultbutton {
  position: absolute;
  right: 70px;
  bottom: -2.2rem;
}
html[dir=rtl] .p-tireoverview .p-resultbutton {
  left: 70px;
  right: auto;
}
.p-tireoverview .p-resultbanners {
  background: rgba(244, 245, 247, 0.44);
  min-height: 120px;
  padding: 2.2rem 0 2.2rem 30px;
  display: flex;
  align-items: center;
}
.p-tireoverview .p-resultbanners__container {
  width: calc(50% - 15px + 79px);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.p-tireoverview .p-resultbanners__bannerimg {
  margin: 5px 10px 5px 0;
  height: 56px;
}
html[dir=rtl] .p-tireoverview .p-resultbanners {
  padding-left: 0;
  padding-right: 30px;
}
.p-tireoverview .p-noresults {
  padding: 0 25px;
}
.p-tireoverview .p-noresults__text a {
  font-weight: 700;
}
.p-tireoverview .p-noresults__highlighted {
  font-weight: 700;
  text-transform: uppercase;
  color: #EB0328;
}
.p-tireoverview .p-noresults__cartypes {
  margin: 60px 0;
}
html.cooper .p-tireoverview .p-searchbar {
  background: #F4F5F7;
}
html.cooper .p-tireoverview .p-resulttext {
  margin-bottom: 2.2rem;
}
html.avon .p-tireoverview .p-resultdescr {
  padding-bottom: 30px;
}
html.avon .p-tireoverview .p-resultbutton {
  background: transparent;
}
@media (max-width: 768px) {
  .p-tireoverview .contentwrapper {
    padding: 0;
  }
  .p-tireoverview .p-maincontents {
    display: block;
    position: relative;
  }
  html.show-mobile-filters .p-tireoverview .p-maincontents:after {
    background: #eeeeee;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 0.95;
    position: absolute;
    right: 0;
    top: 0;
  }
  .p-tireoverview .p-searchbar,
  .p-tireoverview #tireoverview-pagetitle,
  .p-tireoverview .p-sidebar {
    display: none;
  }
  .p-tireoverview .p-mobilefilter {
    display: block;
    width: 100%;
  }
  .p-tireoverview .p-mobilefilter__filter-required {
    background: #ED0000;
    color: #ffffff;
    display: none;
    margin-bottom: 120px;
    padding: 32px 32px 42px;
    text-transform: uppercase;
  }
  html.mobile-filters-required .p-tireoverview .p-mobilefilter__filter-required {
    display: block;
  }
  .p-tireoverview .p-mobilefilter__filter-required-button {
    display: block;
    margin-top: 20px;
    position: absolute;
    width: calc(100% - 64px);
  }
  .p-tireoverview .p-mobilefilter__filter-required-button button {
    width: 100%;
  }
  html.avon .p-tireoverview .p-mobilefilter__filter-required-button .button {
    background: #000;
  }
  html.avon .p-tireoverview .p-mobilefilter__filter-required-button .button:hover {
    background: #fff;
    color: #ed0000;
  }
  html.mobile-filters-required .p-tireoverview .p-mobilefilter__filter-toggle {
    display: none;
  }
  .p-tireoverview .p-mobilefilter .button--white {
    margin: 32px;
    min-width: 0;
  }
  html.cooper .p-tireoverview .p-mobilefilter .button--white {
    border-color: #001d60;
    box-shadow: none;
  }
  .p-tireoverview .p-sidebar {
    height: auto;
    left: 0;
    margin: 32px;
    padding-bottom: 90px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 1;
  }
  html[dir=rtl] .p-tireoverview .p-sidebar {
    margin-right: 30px;
  }
  html.show-mobile-filters .p-tireoverview .p-sidebar {
    display: block;
  }
  .p-tireoverview .p-sidebar__close {
    text-decoration: none;
    display: block;
    font-weight: 700;
    margin: -35px 0 0 -35px;
    padding: 20px 35px;
    text-transform: uppercase;
  }
  html.cooper .p-tireoverview .p-sidebar__close {
    color: #001d60;
  }
  html.avon .p-tireoverview .p-sidebar__close {
    color: #000;
  }
  html.non-touch .p-tireoverview .p-sidebar__close:hover {
    text-decoration: underline;
  }
  html[dir=rtl] .p-tireoverview .p-sidebar__close {
    padding-right: 0;
  }
  .p-tireoverview .p-sidebar__close svg {
    float: right;
    margin-top: 2px;
  }
  html[dir=rtl] .p-tireoverview .p-sidebar__close svg {
    float: left;
  }
  .p-tireoverview .p-sidebar__close .svg-dealer-info-close {
    stroke: #ED0000;
  }
  .p-tireoverview .p-sidebar .p-searchblock--carsearch {
    display: block;
  }
  .p-tireoverview .p-sidebar__apply {
    bottom: 0;
    display: inline;
    position: absolute;
    left: 0;
    margin: 0;
    right: 0;
    width: 100%;
  }
  html.avon .p-tireoverview .p-sidebar__apply {
    max-width: initial;
    transform: none;
  }
  html.avon .p-tireoverview .p-sidebar__apply .button__title {
    transform: none;
  }
  .p-tireoverview .p-searchresults {
    z-index: 0;
  }
  .p-tireoverview .p-resultinfo {
    flex-direction: column;
  }
  .p-tireoverview .p-resultimage-container {
    order: 2;
    width: 100%;
  }
  .p-tireoverview .p-resultimage {
    position: static;
    transform: none;
    display: block;
  }
  .p-tireoverview .p-resulttext {
    width: 100%;
    padding-left: calc(2 * 100vw / 12);
    padding-right: calc(2 * 100vw / 12);
  }
  .p-tireoverview .p-resultimage-container {
    margin-top: 15px;
    padding-left: calc(1 * 100vw / 12);
    padding-right: calc(1 * 100vw / 12);
    display: flex;
    justify-content: center;
  }
  .p-tireoverview .p-resultbutton {
    background: red;
    left: calc(1 * 100vw / 12);
    right: calc(1 * 100vw / 12);
    z-index: 1;
  }
  .p-tireoverview .p-resultbutton .button {
    width: 100%;
  }
  .p-tireoverview .p-resultimagelink {
    height: 332px;
    padding-left: 6px;
    padding-right: 6px;
    max-width: 83vw;
    max-height: 70vw;
  }
  .p-tireoverview .p-resultbanners {
    width: 100%;
    padding-left: calc(1 * 100vw / 12);
    padding-right: calc(1 * 100vw / 12);
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .p-tireoverview .p-resultbanners__container {
    width: 100%;
  }
  .p-tireoverview .p-path,
  .p-tireoverview .p-intro__part {
    padding-left: 8.3333333333vw;
    padding-right: 8.3333333333vw;
  }
}

.speedindex-slider {
  /*ADDME: Want a slider replaced component which supports datalist
  display: none;

  &.wh-form__fieldgroup--pulldown .wh-form__fieldline {
    box-shadow: none;

    &::after {
      display: none;
    }
  }
  */
}

.roadtype-slider {
  display: none;
}

html.cooper.page-tireoverview .crumblepath {
  margin-top: 0;
}

html.avon.page-tireoverview .pageheader {
  min-height: 400px;
}
html.avon.page-tireoverview .pageheader__avonpath {
  top: -75px;
}
@media screen and (max-width: 768px) {
  html.avon.page-tireoverview .p-tireoverview .p-sidebar {
    position: relative;
    top: -230px;
  }
}