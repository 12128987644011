.slideshow .swiper-container {
  width: 100vw;
  height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
  max-height: 60vh;
}
.slideshow__item {
  position: relative;
}
.slideshow__bgimage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.slideshow__bgvideo-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slideshow__bgvideo {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slideshow .swiper-pagination-bullets {
  bottom: auto;
  top: 10px;
}
.slideshow .swiper-pagination-bullet-active {
  background: #001d60;
}
html.avon .slideshow .swiper-pagination-bullet-active {
  background: #ED0000;
}
@media (max-width: 768px) {
  .slideshow {
    display: none;
  }
}