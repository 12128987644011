#cooperbot {
  position: fixed;
  bottom: 70px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
html[dir=ltr] #cooperbot {
  right: 35px;
}
@media (max-width: 400px) {
  html[dir=ltr] #cooperbot {
    right: 15px;
  }
}
html[dir=rtl] #cooperbot {
  left: 35px;
}
@media (max-width: 400px) {
  html[dir=rtl] #cooperbot {
    left: 15px;
  }
}
@media (max-width: 400px) {
  #cooperbot {
    bottom: 15px;
  }
}
#cooperbot > .smalllogo {
  flex: none;
  width: 75%;
  height: auto;
}
#cooperbot .countrysuggestswitch {
  flex: none;
  position: absolute;
  background-color: #fff;
  bottom: 70px;
  width: 350px;
  max-width: calc(100vw - 30px);
  padding: 18px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
}
html[dir=ltr] #cooperbot .countrysuggestswitch {
  right: 0;
}
html[dir=rtl] #cooperbot .countrysuggestswitch {
  left: 0;
}
#cooperbot .countrysuggestswitch__buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
#cooperbot .countrysuggestswitch__buttons:before, #cooperbot .countrysuggestswitch__buttons:after {
  content: "";
  display: block;
  flex: 1 0 0px;
}
#cooperbot .countrysuggestswitch__buttons > .button {
  flex: 0 1 50%;
  height: auto;
  min-height: 40px;
  min-width: 0;
  padding: 5px;
  max-width: calc(50% - 5px);
  white-space: normal;
}
#cooperbot .countrysuggestswitch__buttons > .button > span {
  min-width: 0;
}
#cooperbot .countrysuggestswitch__buttons > .button + .button {
  margin-left: 18px;
}
#cooperbot .countrysuggestswitch p {
  margin-top: 5px;
  color: #9b9b9b;
  padding: 0 30px;
  line-height: 24px;
}
#cooperbot .countrysuggestswitch h3 {
  margin-top: 0;
  margin-bottom: 0;
}
#cooperbot .countrysuggestswitch .countryflag {
  margin-left: 5px;
  width: 24px;
  height: auto;
  position: relative;
  top: 6px;
}