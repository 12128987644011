.featuredlink__contents {
  background: #F4F5F7;
  padding: 4.375rem 18%;
}
.featuredlink__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 72px;
  line-height: 72px;
  margin: 0 0 0.9375rem;
  text-align: center;
}
html.cooper .featuredlink__title {
  color: #001d60;
}
html.avon .featuredlink__title {
  color: #000;
}
.featuredlink__text {
  margin: 0 0 3.125rem;
}
.featuredlink__text p {
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}
html.cooper .featuredlink__text p {
  color: #5C666F;
}
html.avon .featuredlink__text p {
  color: #000;
}
.featuredlink__buttons {
  text-align: center;
}
.featuredlink__button {
  margin: 0 15px;
}
html.avon .featuredlink__title {
  font-size: 72px;
  line-height: 72px;
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
html.cooper html.avon .featuredlink__title {
  color: #001d60;
}
html.avon html.avon .featuredlink__title {
  color: #000;
}
@media (max-width: 576px) {
  html.avon .featuredlink__title {
    font-size: 48px;
    line-height: 48px;
  }
}
html.avon .featuredlink__text p {
  color: #ED0000;
}
@media (max-width: 576px) {
  html.avon .featuredlink__text p {
    font-size: 30px;
    line-height: 40px;
  }
}
html.avon .featuredlink__button {
  margin: 0 2px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
}
html.avon .featuredlink__button + .button {
  background-color: #000;
  border-color: #000;
}
html.avon .featuredlink__button + .button:hover {
  color: #000;
  background-color: #fff;
}
@media (max-width: 576px) {
  html.avon .featuredlink__button {
    width: 100%;
    margin-bottom: 20px;
  }
  html.avon .featuredlink__button .button__title {
    min-width: 0;
  }
}
@media (max-width: 768px) {
  .featuredlink__contents {
    padding-left: 20px;
    padding-right: 20px;
  }
  .featuredlink__button:not(:last-child) {
    margin-bottom: 30px;
  }
}