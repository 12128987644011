html.page-news .pageheader__avonpath {
  top: -75px;
}

.p-news {
  background: #fff;
}
.p-news h1 {
  text-align: center;
}
.p-news__othernews {
  border-top: 4px solid #001d60;
  padding-top: 50px;
}
.p-news__newsrow {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 85px;
}
.p-news__newsitemcontainer {
  width: calc(50% - 15px);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 575px;
}
.p-news__newspage {
  display: none;
}
.p-news__newspage--first {
  display: block;
}
.p-news__pagination {
  margin: 150px 0;
}
html.cooper .p-news {
  padding-bottom: 2.2rem;
}
html.avon .p-news__othernews {
  border-top: 4px solid #000;
}
html.avon .p-news__newsitemcontainer {
  overflow: hidden;
}
html.avon .p-news__contents {
  position: relative;
  top: -70px;
}
@media (max-width: 576px) {
  .p-news__newsrow {
    display: block;
  }
  .p-news__newsitemcontainer {
    width: 100%;
    margin-bottom: 60px;
  }
}