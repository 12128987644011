.button, .swal2-container .swal2-actions button,
.wh-form__button {
  display: inline-flex;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: background 0.3s, color 0.3s, border 0.3s;
  padding: 0 40px;
  border: 1px solid transparent;
  outline: none;
}
html.cooper .button, html.cooper .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.cooper button, html.avon .button, html.avon .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.avon button,
html.cooper .wh-form__button,
html.avon .wh-form__button {
  color: #fff;
}
html.cooper .button[disabled], html.cooper .swal2-container .swal2-actions button[disabled], .swal2-container .swal2-actions html.cooper button[disabled], html.cooper .button[disabled]:hover, html.avon .button[disabled], html.avon .swal2-container .swal2-actions button[disabled], .swal2-container .swal2-actions html.avon button[disabled], html.avon .button[disabled]:hover,
html.cooper .wh-form__button[disabled],
html.cooper .wh-form__button[disabled]:hover,
html.avon .wh-form__button[disabled],
html.avon .wh-form__button[disabled]:hover {
  cursor: not-allowed;
}
html.cooper .button, html.cooper .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.cooper button,
html.cooper .wh-form__button {
  font-size: 18px;
  line-height: 100%;
  background: #001d60;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  height: 4.4rem;
  min-width: 17.1875rem;
  text-transform: uppercase;
  border-color: #001d60;
  font-weight: 700;
}
html.cooper .button[disabled], html.cooper .swal2-container .swal2-actions button[disabled], .swal2-container .swal2-actions html.cooper button[disabled], html.cooper .button[disabled]:hover,
html.cooper .wh-form__button[disabled],
html.cooper .wh-form__button[disabled]:hover {
  background: rgba(0, 29, 96, 0.4);
  border-color: transparent;
  color: #fff;
}
html.cooper .button, html.cooper .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.cooper button, html.cooper .button__title,
html.cooper .wh-form__button,
html.cooper .wh-form__button__title {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.cooper .button:hover, html.cooper .swal2-container .swal2-actions button:hover, .swal2-container .swal2-actions html.cooper button:hover,
html.cooper .wh-form__button:hover {
  background-color: #fff;
  color: #001d60;
}
html.cooper .button--white,
html.cooper .wh-form__button--white {
  background: #fff;
  border-color: transparent;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  color: #001d60;
}
html.cooper .button--white:hover,
html.cooper .wh-form__button--white:hover {
  background: #001d60;
  color: #fff;
}
html.cooper .button--accent-warning,
html.cooper .wh-form__button--accent-warning {
  background: #EB0328;
}
html.cooper .button--accent-warning:hover,
html.cooper .wh-form__button--accent-warning:hover {
  background: #fff;
  color: #EB0328;
}
html.avon .button, html.avon .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.avon button,
html.avon .wh-form__button {
  transform: skew(-40deg);
  font-size: 18px;
  line-height: 100%;
  background: #ed0000;
  height: 4.4rem;
  border-color: #ed0000;
}
html.avon .button .button__title, html.avon .swal2-container .swal2-actions button .button__title, .swal2-container .swal2-actions html.avon button .button__title,
html.avon .wh-form__button .button__title {
  transform: skew(40deg);
}
html.avon .button .wh-form__buttonlabel, html.avon .swal2-container .swal2-actions button .wh-form__buttonlabel, .swal2-container .swal2-actions html.avon button .wh-form__buttonlabel,
html.avon .wh-form__button .wh-form__buttonlabel {
  transform: skew(40deg);
  display: inline-block;
}
html.avon .button, html.avon .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.avon button, html.avon .button__title,
html.avon .wh-form__button,
html.avon .wh-form__button__title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.avon .button > span, html.avon .swal2-container .swal2-actions button > span, .swal2-container .swal2-actions html.avon button > span,
html.avon .wh-form__button > span {
  min-width: 240px;
  text-align: center;
}
html.avon .button:hover, html.avon .swal2-container .swal2-actions button:hover, .swal2-container .swal2-actions html.avon button:hover,
html.avon .wh-form__button:hover {
  background: #fff;
  color: #ed0000;
}
html.avon .button--avon-non-skewed,
html.avon .wh-form__button--avon-non-skewed {
  transform: skew(0);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  justify-content: center;
}
html.avon .button--avon-non-skewed .button__title,
html.avon .wh-form__button--avon-non-skewed .button__title {
  transform: skew(0);
}
html.avon.isie .button, html.avon.isie .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.avon.isie button,
html.avon.isie .wh-form__button {
  max-width: none;
}
@media (max-width: 768px) {
  html.cooper .button, html.cooper .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.cooper button,
  html.cooper .wh-form__button {
    box-shadow: 0 2px 15px 0 rgba(0, 29, 96, 0.5);
  }
}

.embeddedobject--button--fullwidth {
  padding-left: 25px;
  padding-right: 25px;
}
.embeddedobject--button--fullwidth .button, .embeddedobject--button--fullwidth .swal2-container .swal2-actions button, .swal2-container .swal2-actions .embeddedobject--button--fullwidth button {
  width: 100%;
  max-width: 720px;
}

.swal2-container .swal2-actions button {
  border-radius: 0;
}
.swal2-container .swal2-actions button > .button__title {
  min-width: 100px !important;
}
.swal2-container .swal2-actions button:focus {
  box-shadow: none;
}
.swal2-container .swal2-actions button:hover {
  background-image: none !important;
}
html.cooper .swal2-container .swal2-actions button {
  min-width: 150px;
}
html.cooper .swal2-container .swal2-actions button.swal2-confirm {
  border-color: #001d60 !important;
}
html.cooper .swal2-container .swal2-actions button.swal2-cancel {
  background-color: #EB0328;
  border-color: #EB0328 !important;
}

.cartypeselector__choices {
  display: flex;
  justify-content: space-between;
}
.cartypeselector__choice {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
}
.cartypeselector__carimg {
  display: block;
  margin: 0 0 0.9375rem;
}
html[dir=rtl] .cartypeselector__carimg {
  transform: scaleX(-1);
}
.cartypeselector__cartitle {
  font-size: 13.5px;
  line-height: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.cartypeselector__gt {
  display: inline-block;
  margin-left: 0.375rem;
}
html[dir=rtl] .cartypeselector__gt {
  margin-left: 0;
  margin-right: 0.375rem;
  transform: scaleX(-1);
}
.cartypeselector--force-mobile .cartypeselector__choices {
  flex-wrap: wrap;
}
.cartypeselector--force-mobile .cartypeselector__choice {
  width: 50%;
  align-items: center;
  padding: 15px;
}
.cartypeselector--force-mobile .cartypeselector__choice:nth-child(1), .cartypeselector--force-mobile .cartypeselector__choice:nth-child(2) {
  border-bottom: 1px solid #001d60;
}
.cartypeselector--force-mobile .cartypeselector__choice:nth-child(1), .cartypeselector--force-mobile .cartypeselector__choice:nth-child(3) {
  border-right: 1px solid #001d60;
}
html[dir=rtl] .cartypeselector--force-mobile .cartypeselector__choice:nth-child(1), html[dir=rtl] .cartypeselector--force-mobile .cartypeselector__choice:nth-child(3) {
  border-left: 1px solid #001d60;
  border-right: 0;
}
.cartypeselector--force-mobile .cartypeselector__carimg {
  width: 100%;
  height: auto;
  flex: none;
  max-width: 250px;
}
.cartypeselector--force-mobile .cartypeselector__cartitle {
  font-size: 16px;
  line-height: 100%;
}
.cartypeselector--force-mobile .cartypeselector__gt {
  display: none;
}
html.avon .cartypeselector--force-mobile .cartypeselector__choice {
  border-color: #000;
}
html.avon-us .cartypeselector__choices {
  justify-content: center;
}
html.avon-us .cartypeselector__choice {
  margin: 0 25px;
}
html.avon-us .cartypeselector__choice:nth-child(1) {
  border-right: 0;
}
html.avon-us .cartypeselector__choice:last-child {
  border-bottom: 0;
}
@media (max-width: 768px) {
  html.avon-us .cartypeselector .cartypeselector__carimg--motorcycle {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .cartypeselector .cartypeselector__choices {
    flex-wrap: wrap;
  }
  .cartypeselector .cartypeselector__choice {
    width: 50%;
    align-items: center;
    padding: 15px;
  }
  .cartypeselector .cartypeselector__choice:nth-child(1), .cartypeselector .cartypeselector__choice:nth-child(2) {
    border-bottom: 1px solid #001d60;
  }
  .cartypeselector .cartypeselector__choice:nth-child(1), .cartypeselector .cartypeselector__choice:nth-child(3) {
    border-right: 1px solid #001d60;
  }
  html[dir=rtl] .cartypeselector .cartypeselector__choice:nth-child(1), html[dir=rtl] .cartypeselector .cartypeselector__choice:nth-child(3) {
    border-left: 1px solid #001d60;
    border-right: 0;
  }
  .cartypeselector .cartypeselector__carimg {
    width: 100%;
    height: auto;
    flex: none;
    max-width: 250px;
  }
  .cartypeselector .cartypeselector__cartitle {
    font-size: 16px;
    line-height: 100%;
  }
  .cartypeselector .cartypeselector__gt {
    display: none;
  }
  html.avon .cartypeselector .cartypeselector__choice {
    border-color: #000;
  }
}

.circlearrow {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0;
}
.circlearrow:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 2px solid rgba(0, 29, 96, 0.35);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.6s;
  z-index: 0;
}
.circlearrow__outer {
  fill: transparent;
  stroke: #EB0328;
  stroke-width: 2;
  stroke-dasharray: 200;
  transition: stroke-dashoffset 0.5s ease-out;
  animation-play-state: running;
  stroke-dashoffset: 200 !important;
}
.circlearrow__coopersvg--outer {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.circlearrow__coopersvg--line, .circlearrow__coopersvg--arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circlearrow__coopersvg--line svg, .circlearrow__coopersvg--arrow svg {
  display: block;
}
.circlearrow__coopersvg--arrow {
  margin: 0;
  transition: margin 0.25s;
}
.circlearrow__coopersvg--arrow svg {
  width: 6px;
  height: 10px;
}
.circlearrow__coopersvg--line {
  overflow: hidden;
  width: 0;
  transition: width 0.25s;
}
.circlearrow__coopersvg--line svg {
  width: 14px;
  height: 3px;
}
.circlearrow__avonsvg {
  display: none;
}
html.non-touch .circlearrow__hoverelement:hover .circlearrow__coopersvg--outer .circlearrow__outer {
  stroke-dashoffset: 0 !important;
}
html.non-touch .circlearrow__hoverelement:hover .circlearrow__coopersvg--arrow {
  margin-left: 4px;
}
html.non-touch .circlearrow__hoverelement:hover .circlearrow__coopersvg--line {
  width: 14px;
}
html.non-touch .circlearrow__hoverelement:hover .circlearrow:after {
  box-shadow: 0px 0px 0px 5px rgba(0, 29, 96, 0.35);
}
.circlearrow__link-container {
  display: flex;
  align-items: center;
}
.circlearrow__link a,
.circlearrow__link .circlearrow {
  display: block;
}
.circlearrow__link-title {
  font-size: 24px;
  line-height: 100%;
  text-decoration: none;
  margin: 0 0 0 16px;
  position: relative;
  top: -1px;
}
html.cooper .circlearrow__link-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .circlearrow__link-title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
html.cooper .circlearrow__link-title, html.avon .circlearrow__link-title {
  color: #fff;
}
html.avon .circlearrow {
  border-radius: 0;
  border: 2px solid rgba(51, 51, 51, 0.2);
  position: relative;
  width: 34px;
  height: 34px;
  transition: border 0.3s;
}
html.avon .circlearrow .svg-circlearrow-arrow-stroke,
html.avon .circlearrow .svg-circlearrow-line-stroke {
  stroke: #000;
}
html.avon .circlearrow__link-title {
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
html.avon .circlearrow__coopersvg--outer {
  display: none;
}
html.avon .circlearrow:after {
  display: none;
}
html.non-touch html.avon .circlearrow__hoverelement:hover .circlearrow {
  border-color: #000;
}
html[dir=rtl] .circlearrow {
  transform: scaleX(-1);
}
html[dir=rtl] .circlearrow__link-title {
  margin-right: 16px;
  margin-left: 0;
}

html.avon.non-touch .circlearrow__hoverelement:hover .circlearrow {
  border-color: rgba(51, 51, 51, 0.5);
}

.circlearrow--white > .circlearrow {
  color: #fff;
}
.circlearrow--white > .circlearrow:after {
  z-index: 0;
}
.circlearrow--white > .circlearrow .svg-circlearrow-arrow-stroke,
.circlearrow--white > .circlearrow .svg-circlearrow-line-stroke {
  stroke: #fff;
}
.circlearrow--white > .circlearrow:after {
  border-color: rgba(255, 255, 255, 0.35);
}
html.avon .circlearrow--white > .circlearrow {
  border: 2px solid rgba(255, 255, 255, 0.2);
}
html.avon .circlearrow--white > .circlearrow .svg-circlearrow-arrow-stroke,
html.avon .circlearrow--white > .circlearrow .svg-circlearrow-line-stroke {
  stroke: #fff;
}

html.non-touch .circlearrow__hoverelement:hover .circlearrow--white > .circlearrow:after {
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.35);
}
html.avon.non-touch .circlearrow__hoverelement:hover .circlearrow--white > .circlearrow {
  border-color: rgba(255, 255, 255, 0.5);
}

.countryselector {
  height: 36px;
  padding-top: 7px;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.countryselector__locale {
  font-size: 24px;
  line-height: 100%;
  margin: 0 20px 0 12px;
}
html.cooper .countryselector__locale {
  color: #001d60;
}
html.avon .countryselector__locale {
  color: #000;
}
html.cooper .countryselector__locale {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .countryselector__locale {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.countryselector__change {
  font-size: 14px;
  line-height: 100%;
}
.countryselector__pulldown {
  position: absolute;
  min-width: 155px;
  max-width: calc(100vw - 60px);
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  display: none;
  z-index: 100;
}
.countryselector__pulldown img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 10px;
}
.countryselector__pulldown a {
  text-decoration: none;
  font: inherit;
  color: inherit;
  display: block;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  color: #001d60;
  font-size: 16px;
  padding-right: 10px;
}
.countryselector__pulldown ul {
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
  background-color: #fff;
  transition: background-color 0.3s;
}
.countryselector__pulldown ul > li {
  min-height: 30px;
  padding-left: 15px;
}
.countryselector__pulldown ul > li + li a {
  border-top: 1px solid #f1f2f6;
}
.countryselector__pulldown ul > li:hover {
  background-color: #f1f2f6;
}
html[dir=rtl] .countryselector__pulldown {
  right: auto;
  left: 0;
}
html[dir=rtl] .countryselector__pulldown img {
  margin-right: 0;
  margin-left: 10px;
}
html[dir=rtl] .countryselector__pulldown a {
  padding-right: 0;
  padding-left: 10px;
}
html[dir=rtl] .countryselector__pulldown ul > li {
  padding-left: 0;
  padding-right: 15px;
}
.countryselector:hover .countryselector__pulldown {
  display: block;
}

.mobilemenu .countryselector {
  padding-top: 0;
}
.mobilemenu .countryselector__selected img {
  height: 16px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}
.mobilemenu .countryselector__pulldown {
  top: auto;
  bottom: 100%;
  right: auto;
  left: 0;
}
html[dir=rtl] .mobilemenu .countryselector__selected img {
  margin-right: 5px;
  margin-left: 0;
}
html[dir=rtl] .mobilemenu .countryselector__pulldown {
  right: 0;
  left: auto;
}

.copyright {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F4F5F7;
  position: relative;
}
.copyright__year {
  margin: 23px 0 0;
  font-size: 18px;
  line-height: 100%;
}
html.cooper .copyright__year {
  color: #001d60;
}
html.avon .copyright__year {
  color: #000;
}
html.cooper .copyright__year {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .copyright__year {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.avon .copyright {
  padding: 20px 0;
  background: #fff;
}
@media (max-width: 576px) {
  .copyright {
    padding-top: 25px;
  }
}

.crumblepath {
  margin-top: 20px;
  display: flex;
}
.crumblepath__item {
  color: #5C666F;
  text-transform: uppercase;
}
.crumblepath__item--last {
  font-weight: 700;
}
html.cooper .crumblepath__item--last {
  color: #001d60;
}
html.avon .crumblepath__item--last {
  color: #000;
}
.crumblepath__item--link {
  text-decoration: none;
}
html.non-touch .crumblepath__item--link:hover {
  text-decoration: underline;
}
.crumblepath__divider {
  margin: 0 0.375rem;
}
@media (max-width: 768px) {
  .crumblepath {
    padding-left: 12px;
    margin-bottom: 20px;
  }
  html[dir=rtl] .crumblepath {
    padding-right: 12px;
    padding-left: 0;
  }
}
html.avon .crumblepath {
  margin-top: 0;
  position: relative;
}
html.avon.page-search .crumblepath {
  top: -211px;
}

.fourimageblocks {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}
.fourimageblocks__block {
  width: calc(25% - 21px);
  margin-right: 21px;
}
.fourimageblocks__block:last-child {
  margin-right: 0;
}
html[dir=rtl] .fourimageblocks__block {
  margin-left: 21px;
  margin-right: 0;
}
.fourimageblocks__block-img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0 0 2.5rem;
}
.fourimageblocks__block-title {
  margin: 0 0 1.25rem;
}
.fourimageblocks__block-title,
.fourimageblocks__block-title a {
  font-size: 24px;
  line-height: 100%;
  text-transform: uppercase;
}
html.cooper .fourimageblocks__block-title,
html.cooper .fourimageblocks__block-title a {
  color: #001d60;
}
html.avon .fourimageblocks__block-title,
html.avon .fourimageblocks__block-title a {
  color: #000;
}
html.cooper .fourimageblocks__block-title,
html.cooper .fourimageblocks__block-title a {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .fourimageblocks__block-title,
html.avon .fourimageblocks__block-title a {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.fourimageblocks__block-title a {
  text-decoration: none;
}
html.non-touch .fourimageblocks__block-title a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .fourimageblocks__blocks {
    flex-wrap: wrap;
  }
  .fourimageblocks__block {
    width: calc(50% - 21px);
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .fourimageblocks__block {
    width: 100%;
    margin-right: 0;
  }
}

.lineup--singlecategory .lineup__categorylinkcontainer.first {
  text-align: center;
}
.lineup__title {
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  text-align: center;
  margin-bottom: 25px;
}
.lineup__tabsholder {
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 30px;
  white-space: nowrap;
  height: 70px;
}
@media screen and (max-width: 690px) {
  .lineup__tabsholder {
    margin-bottom: 0;
  }
}
.lineup__tabs {
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: calc(100% - 30px);
}
.lineup__tab {
  font-size: 24px;
  line-height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}
html.cooper .lineup__tab {
  color: #001d60;
}
html.avon .lineup__tab {
  color: #000;
}
.lineup__tab:after {
  transition: width 0.3s;
  opacity: 1;
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 0;
  height: 3px;
  background: #EB0328;
}
.lineup__tab.selected:after {
  width: 100%;
}
.lineup__divider {
  color: #EB0328;
  margin: 0 30px;
}
html.avon .lineup__divider {
  display: none;
}
html.avon .lineup__tabs {
  flex-flow: wrap;
}
html.avon .lineup__tab {
  font-size: 18px;
  line-height: 100%;
  color: #A0A0A0;
  letter-spacing: 0.3px;
  margin: 0 25px;
  transition: background 0.3s, color 0.3s;
  padding: 12px 22px;
  display: block;
}
html.avon .lineup__tab:after {
  display: none;
}
html.avon .lineup__tab.selected {
  background: #ED0000;
  color: #fff;
  font-weight: 700;
}
html.avon .lineup__categorylinkcontainer {
  flex: initial;
  white-space: nowrap;
}
@media screen and (max-width: 810px) {
  html.avon .lineup__title {
    font-size: 28px;
    line-height: 100%;
    text-align: center;
  }
  html.avon .lineup__tab {
    font-size: 12px;
    line-height: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 8px;
  }
  html.avon .lineup__tab.selected {
    font-weight: 400;
  }
}
@media screen and (max-width: 690px) {
  .lineup__title, .lineup__tabs {
    padding-left: 23px;
    padding-right: 23px;
  }
  .lineup__tabs {
    max-width: none;
  }
  .lineup__title {
    font-size: 30px;
    line-height: 100%;
    text-align: left;
    letter-spacing: 0.01px;
  }
  .lineup__tab {
    font-size: 16px;
    line-height: 100%;
  }
  .lineup__divider {
    font-size: 12px;
    margin: 0 20px;
  }
  .lineup__categorylinkcontainer {
    flex: initial;
  }
  .lineup__categorylinkcontainer, .lineup__categorylinkcontainer.first {
    text-align: left;
  }
  html.cooper .lineup__tabs {
    justify-content: flex-start;
  }
}

.newsblock {
  width: calc(50% - 15px);
  height: 570px;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 56px 68px;
  text-decoration: none;
  overflow: hidden;
}
html.non-touch .newsblock:hover .newsblock__bgimage,
html.non-touch .newsblock:hover .newsblock__video {
  transform: scale(1.1);
}
.newsblock__twoblockscontainer {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1170px;
}
@media screen and (max-width: 1170px) {
  .newsblock__twoblockscontainer {
    padding: 0 13px;
  }
}
.newsblock__overlay, .newsblock__bgimage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.newsblock__overlay {
  height: 419px;
  background-image: linear-gradient(0deg, #001d60 0%, rgba(0, 0, 0, 0) 100%);
}
.newsblock__bgimage, .newsblock__video {
  transition: transform 0.3s;
}
.newsblock__bgimage {
  top: 0;
}
.newsblock__textcontainer {
  position: relative;
}
.newsblock__text-title {
  font-size: 40px;
  line-height: 100%;
  text-transform: uppercase;
  letter-spacing: 0.01px;
  color: #fff;
  margin: 0 0 20px;
}
html.cooper .newsblock__text-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .newsblock__text-title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.newsblock__video {
  position: absolute;
  top: 0;
  left: -9999px;
  right: -9999px;
  margin: 0 auto;
  height: 100%;
  /* 1. No object-fit support: */
  /* 2. If supporting object-fit, overriding (1): */
}
@media (min-aspect-ratio: 16/9) {
  .newsblock__video {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .newsblock__video {
    width: 300%;
    left: -100%;
  }
}
@supports (object-fit: cover) {
  .newsblock__video {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
html.avon .newsblock__overlay {
  background-image: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
@media (max-width: 768px) {
  .newsblock {
    width: 100%;
    height: 500px;
    margin-bottom: 25px;
    padding: 40px 50px;
  }
  .newsblock:last-child {
    margin-bottom: 0;
  }
  .newsblock__twoblockscontainer {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .newsblock {
    padding: 30px 40px;
  }
  .newsblock__text-title {
    font-size: 32px;
    line-height: 100%;
  }
}

.imagetextblock__container {
  width: calc(50% - 15px);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 575px;
}
.imagetextblock__textcontainer--hide {
  padding: 0;
}
.imagetextblock__textcontainer .button, .imagetextblock__textcontainer .swal2-container .swal2-actions button, .swal2-container .swal2-actions .imagetextblock__textcontainer button {
  position: absolute;
}
.imagetextblock__image-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-decoration: none;
}
.imagetextblock__imagelink {
  display: block;
  width: 100%;
  height: 100%;
}
.imagetextblock__text-title {
  margin: 0 0 10px;
}
.imagetextblock__bgimage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
html.cooper .imagetextblock {
  padding-bottom: 2.2rem;
}
html.cooper .imagetextblock__bgimage {
  position: static;
  flex: 1;
  width: 100%;
}
html.cooper .imagetextblock__image-container {
  flex: 1;
  position: relative;
}
html.cooper .imagetextblock__image-container .button, html.cooper .imagetextblock__image-container .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.cooper .imagetextblock__image-container button {
  position: absolute;
  bottom: -2.2rem;
  right: 15px;
  z-index: 10;
}
html.cooper .imagetextblock__textcontainer {
  width: 100%;
  padding: 35px 45px 70px 70px;
  background: #F4F5F7;
  position: relative;
}
html.cooper .imagetextblock__textcontainer--hide {
  padding: 0;
}
html.cooper .imagetextblock__text-title {
  font-size: 24px;
  line-height: 30px;
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #001D60;
  text-transform: uppercase;
}
html.cooper .imagetextblock__text-description {
  font-size: 18px;
  line-height: 25px;
}
html.cooper html.cooper .imagetextblock__text-description {
  color: #5C666F;
}
html.avon html.cooper .imagetextblock__text-description {
  color: #000;
}
html.cooper[dir=rtl] .imagetextblock__image-container .button, html.cooper[dir=rtl] .imagetextblock__image-container .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.cooper[dir=rtl] .imagetextblock__image-container button {
  left: 15px;
  right: auto;
}
html.cooper[dir=rtl] .imagetextblock__textcontainer {
  padding-left: 45px;
  padding-right: 70px;
}
html.avon .imagetextblock__container {
  overflow: hidden;
}
html.avon .imagetextblock__image-container {
  overflow: hidden;
}
html.avon .imagetextblock__image-container .button, html.avon .imagetextblock__image-container .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.avon .imagetextblock__image-container button {
  position: relative;
  bottom: -2px;
  right: -30px;
  border: 0;
}
html.avon .imagetextblock__image-container .button .button__title, html.avon .imagetextblock__image-container .swal2-container .swal2-actions button .button__title, .swal2-container .swal2-actions html.avon .imagetextblock__image-container button .button__title {
  padding-right: 10px;
}
html.avon .imagetextblock__textcontainer {
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0 40px;
  background: #fff;
}
html.avon .imagetextblock__textcontainer--hide {
  height: 0;
}
html.avon .imagetextblock__textcontainer--hide::before {
  display: none !important;
}
html.avon .imagetextblock__text-title {
  font-size: 30px;
  line-height: 100%;
  margin: 30px 0 15px;
  font-weight: 700;
}
html.avon .imagetextblock__text-description {
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 25px;
}
html.avon[dir=rtl] .imagetextblock .imagetextblock__image-container .button, html.avon[dir=rtl] .imagetextblock .imagetextblock__image-container .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.avon[dir=rtl] .imagetextblock .imagetextblock__image-container button {
  right: auto;
  left: -30px;
}
@media (max-width: 768px) {
  html.cooper .imagetextblock__textcontainer, html.avon .imagetextblock__textcontainer {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  html.cooper .imagetextblock__textcontainer .button, html.cooper .imagetextblock__textcontainer .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.cooper .imagetextblock__textcontainer button, html.avon .imagetextblock__textcontainer .button, html.avon .imagetextblock__textcontainer .swal2-container .swal2-actions button, .swal2-container .swal2-actions html.avon .imagetextblock__textcontainer button {
    left: 29px;
    right: 31px;
    max-width: initial;
  }
}

.pagesidebar {
  display: flex;
}
.pagesidebar__contents {
  flex: 1;
  padding-right: 100px;
}
html[dir=rtl] .pagesidebar__contents {
  padding-left: 100px;
  padding-right: 0;
}
.pagesidebar__sidebar {
  flex: 0 0 370px;
  padding-top: 1.875rem;
}
html.avon .pagesidebar {
  position: relative;
  top: -30px;
}
html.page-press .pagesidebar__contents {
  padding-right: 65px;
}
html.page-press .pagesidebar__sidebar {
  flex: 0 0 270px;
}
html.page-press[dir=rtl] .pagesidebar__contents {
  padding-right: 0;
  padding-left: 65px;
}
@media (max-width: 768px) {
  .pagesidebar {
    display: block;
  }
  html[dir=ltr] .pagesidebar__contents {
    padding-right: 0;
  }
  html[dir=rtl] .pagesidebar__contents {
    padding-left: 0;
  }
  .pagesidebar__sidebar {
    padding-top: 0;
  }
}

.pagination {
  display: flex;
  justify-content: center;
}
.pagination ul,
.pagination li {
  padding: 0;
  margin: 0;
}
.pagination ul {
  list-style: none;
  display: flex;
}
.pagination li {
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
}
.pagination__prev, .pagination__next {
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
  text-decoration: none;
  position: relative;
}
html.cooper .pagination__prev, html.cooper .pagination__next {
  color: #001d60;
}
html.avon .pagination__prev, html.avon .pagination__next {
  color: #000;
}
.pagination__prev:after, .pagination__next:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 47px;
  height: 15px;
  background: no-repeat url("../../web/img/long-arrow.svg");
}
.pagination__next {
  margin-left: 56px;
  padding-right: 13px;
}
.pagination__prev {
  margin-right: 56px;
  padding-left: 13px;
}
.pagination__prev:after {
  left: 0;
  transform: translate(-100%, -50%) scaleX(-1);
}
.pagination li.pagination__wrapper-page-link, .pagination__ellipsis {
  display: flex;
  width: 70px;
  height: 70px;
  border: 2px solid #001d60;
  position: relative;
  margin: 0 7px;
}
html.avon .pagination li.pagination__wrapper-page-link, html.avon .pagination__ellipsis {
  border-color: #ED0000;
}
.pagination li.pagination__wrapper-page-link a,
.pagination li.pagination__wrapper-page-link span, .pagination__ellipsis a,
.pagination__ellipsis span {
  font-size: 18px;
  line-height: 100%;
  color: #001d60;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  transition: color 0.3s, background 0.3s;
}
.pagination li.pagination__wrapper-page-link .pagination__current, .pagination__ellipsis .pagination__current {
  background: #EB0328;
  color: #fff;
}
.pagination__disabled {
  opacity: 0.4;
  pointer-events: none;
}
@media screen and (max-width: 1024px) {
  .pagination li.pagination__wrapper-page-link, .pagination__ellipsis {
    width: 60px;
    height: 60px;
    margin: 0 5px;
  }
  .pagination li.pagination__wrapper-page-link a,
  .pagination li.pagination__wrapper-page-link span, .pagination__ellipsis a,
  .pagination__ellipsis span {
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
  }
  .pagination__next {
    margin-left: 20px;
    padding-right: 8px;
  }
  .pagination__prev {
    margin-right: 20px;
    padding-left: 8px;
  }
}
@media screen and (max-width: 850px) {
  .pagination__next, .pagination__prev {
    font-size: 0;
    margin: 0 8px;
    padding: 0;
  }
}
html.avon .pagination__prev:after, html.avon .pagination__next:after {
  background: no-repeat url("../../web/img/long-arrow-avon.svg");
}
html.avon .pagination li.pagination__wrapper-page-link, html.avon .pagination__ellipsis {
  border-color: #EB0328;
}
html.avon .pagination li.pagination__wrapper-page-link a,
html.avon .pagination li.pagination__wrapper-page-link span, html.avon .pagination__ellipsis a,
html.avon .pagination__ellipsis span {
  color: #000;
}
html.avon .pagination li.pagination__wrapper-page-link .pagination__current, html.avon .pagination__ellipsis .pagination__current {
  background: #ED0000;
  color: #fff;
  font-weight: bold;
}

.skewedblocks {
  font-size: 0;
}
.skewedblocks--align-right {
  display: flex;
  justify-content: flex-end;
}
.skewedblocks__block {
  display: inline-block;
  height: 75px;
  width: 130px;
  transform: skew(-40deg);
  position: relative;
  margin-left: -1px;
}
.skewedblocks__block--white {
  background: #fff;
}
.skewedblocks__block--gray {
  background: #f4f5f7;
}

.tirefeatures {
  min-height: 750px;
  background-color: #F4F5F7;
}
.tirefeatures .contentwrapper {
  min-height: inherit;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}
.tirefeatures__info {
  width: 400px;
  color: #fff;
  padding: 70px 0;
}
.tirefeatures__title {
  font-size: 72px;
  line-height: 70px;
  margin: 1.125rem 0;
  padding: 0 0 1.25rem;
  border-bottom: 1px solid #F4F5F7;
}
html.cooper .tirefeatures__title, html.avon .tirefeatures__title {
  color: #F4F5F7;
}
html.cooper .tirefeatures__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .tirefeatures__title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.tirefeatures__feature {
  padding-left: 0.625rem;
  margin: 0 0 0.9375rem;
  text-transform: uppercase;
}
html[dir=rtl] .tirefeatures__feature {
  padding-left: 0;
  padding-right: 0.625rem;
}
.tirefeatures__feature-descr {
  font-weight: 400;
  text-transform: initial;
}
.tirefeatures__hotspotsimagecontainer {
  position: relative;
  margin-left: 50px;
}
html[dir=rtl] .tirefeatures__hotspotsimagecontainer {
  margin-left: 0;
  margin-right: 50px;
}
.tirefeatures__hotspotcontainer {
  position: absolute;
}
.tirefeatures__hotspotcontainer.show .tirefeatures__hotspotinfowindow {
  opacity: 1;
  pointer-events: auto;
}
.tirefeatures__hotspot {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 100%;
  color: #001d60;
  letter-spacing: 0.01px;
  background: #fff;
  text-decoration: none;
  border-radius: 100%;
  padding: 10px 20px;
  box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.3);
  transition: color 0.3s, background 0.3s;
  cursor: pointer;
  display: block;
  outline: none;
}
.tirefeatures__hotspot:hover {
  color: #fff;
  background: #001d60;
}
.tirefeatures__hotspotinfowindow {
  position: absolute;
  top: 100%;
  left: 100%;
  background: #fff;
  padding: 40px 20px 20px;
  width: 300px;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}
.tirefeatures__hotspotinfowindow-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
html[dir=rtl] .tirefeatures__hotspotinfowindow-close {
  left: 20px;
  right: auto;
}
.tirefeatures--heroimage {
  background-color: none;
  background-image: linear-gradient(-205deg, #373737 35%, #000 100%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.tirefeatures--heroimage ol.tirefeatures__features {
  padding-left: 1em;
  font-weight: 700;
}
.tirefeatures--heroimage .tirefeatures__image {
  position: absolute;
  bottom: -120px;
  left: 450px;
  height: 100%;
}
html.type-motorsport .tirefeatures--heroimage .tirefeatures__image, html.type-motorcycle .tirefeatures--heroimage .tirefeatures__image {
  bottom: -445px;
  height: 141%;
}
html[dir=rtl] .tirefeatures--heroimage .tirefeatures__image {
  left: 0;
  right: 450px;
}
.tirefeatures--hotspots {
  min-height: 0;
  padding: 40px 0 0;
}
.tirefeatures--hotspots .contentwrapper {
  align-items: flex-end;
}
.tirefeatures--hotspots .tirefeatures__title {
  color: #001d60 !important;
  border-color: #001d60;
}
html.avon .tirefeatures--hotspots .tirefeatures__title {
  color: #F4F5F7 !important;
}
.tirefeatures--hotspots .tirefeatures__features {
  color: #5C666F;
}
.tirefeatures--hotspots .tirefeatures__info {
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tirefeatures--hotspots .tirefeatures__image {
  position: static;
  display: block;
}
@media (max-width: 768px) {
  .tirefeatures--hotspots .contentwrapper {
    flex-direction: column;
  }
  .tirefeatures--hotspots .tirefeatures__info {
    width: 100%;
    padding-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .tirefeatures--hotspots .tirefeatures__hotspotinfowindow {
    position: fixed;
    top: 50%;
    left: 50%;
    min-height: 50vh;
    max-height: 70vh;
    width: 80vw;
    transform: translate(-50%, -50%) !important;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .tirefeatures--hotspots .tirefeatures__image {
    max-width: 90vw;
    height: auto;
  }
}
html.avon .tirefeatures--hotspots {
  background-image: linear-gradient(-205deg, #373737 35%, #000000 100%);
}
html.avon .tirefeatures--hotspots .tirefeatures__hotspot {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #ED0000;
  color: #fff;
  font-size: 20px;
  padding: 10px 15px;
}
html.avon .tirefeatures--hotspots .tirefeatures__hotspot:hover {
  background: #fff;
  color: #ED0000;
}
html.avon .tirefeatures--hotspots .tirefeatures__title {
  color: #F4F5F7;
  border-color: #F4F5F7;
}
html.avon .tirefeatures--hotspots .tirefeatures__features,
html.avon .tirefeatures--hotspots .tirefeatures__features a {
  color: #fff;
}

.tireimageblock {
  height: 396px;
  background-image: linear-gradient(212deg, #001d60 30%, #000309 100%);
  padding: 22px 25px;
  position: relative;
  display: block;
  text-decoration: none;
}
html.non-touch .tireimageblock:hover .tireimageblock__image {
  transform: scale(1.1);
}
.tireimageblock__title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 60px; /* Fallback for non-webkit */
  font-size: 30px !important;
  line-height: 1 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  letter-spacing: 1px;
}
html.cooper .tireimageblock__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .tireimageblock__title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.tireimageblock__imagecontainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.tireimageblock__image {
  position: absolute;
  width: 296px;
  height: 296px;
  bottom: 0;
  right: 0;
  transition: transform 0.25s;
}
.tireimageblock__payoffcontainer {
  width: 183px;
  height: 181px;
  background: url("../../web/img/tirepayoff.svg") no-repeat;
  z-index: 100;
  position: absolute;
  bottom: -6px;
  right: -6px;
}
.tireimageblock__payoff {
  font-size: 20px;
  line-height: 100%;
  position: absolute;
  color: #fff;
  transform: rotate(-45deg);
  letter-spacing: 0.01px;
  top: 83px;
  left: 26px;
  width: 160px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
html.cooper .tireimageblock__payoff {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .tireimageblock__payoff {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
html.avon .tireimageblock {
  background: #DBDDE2;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.1);
}
html.avon .tireimageblock__title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 50px; /* Fallback for non-webkit */
  font-size: 24px !important;
  line-height: 1.0416666667 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.3px;
}
html.avon .tireimageblock__payoff {
  font-size: 15px;
  line-height: 100%;
  padding-bottom: 3px;
}