.embeddedobject--campaignblock2 {
  background-image: linear-gradient(223deg, #373737 0%, #000000 61%);
  color: #fff;
}
.embeddedobject--campaignblock2 .contentwrapper {
  display: flex;
  align-items: flex-end;
  min-height: 520px;
}
.embeddedobject--campaignblock2 .contentwrapper > div {
  flex: 0 0 50%;
  max-width: 50%;
}
.embeddedobject--campaignblock2 .campaignblock2__info {
  align-self: center;
  padding: 20px 0;
}
.embeddedobject--campaignblock2 .campaignblock2__info__suptitle {
  font-weight: 300;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-shadow: 0 0 1px #fff;
}
.embeddedobject--campaignblock2 .campaignblock2__info__title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 60px;
  line-height: 100%;
  margin-bottom: 25px;
}
.embeddedobject--campaignblock2 .campaignblock2__info__description {
  font-size: 18px;
  color: #A0A0A0;
  margin-bottom: 35px;
  line-height: 120%;
}
.embeddedobject--campaignblock2 .campaignblock2__info__link .circlearrow__link-title {
  font-size: 18px;
  text-transform: uppercase;
}
html.avon .embeddedobject--campaignblock2 .campaignblock2__info__link .circlearrow__link-title {
  color: #ED0000;
}
.embeddedobject--campaignblock2 .campaignblock2__image {
  margin-left: 7%;
}
.embeddedobject--campaignblock2 .campaignblock2__image img {
  vertical-align: bottom;
  height: 420px;
  max-height: 75%;
}
@media (max-width: 768px) {
  .embeddedobject--campaignblock2 {
    background-image: linear-gradient(218deg, #373737 0%, #000000 89%);
  }
  .embeddedobject--campaignblock2 .contentwrapper {
    display: block;
    min-height: 0;
    padding: 30px 25px 0;
  }
  .embeddedobject--campaignblock2 .contentwrapper > div {
    max-width: 100%;
  }
  .embeddedobject--campaignblock2 .campaignblock2__info__suptitle {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .embeddedobject--campaignblock2 .campaignblock2__info__title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .embeddedobject--campaignblock2 .campaignblock2__info__description {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .embeddedobject--campaignblock2 .campaignblock2__info__link .circlearrow__link {
    display: none;
  }
  .embeddedobject--campaignblock2 .campaignblock2__info__link .circlearrow__link-title {
    font-size: 16px;
    margin-left: 0;
  }
  .embeddedobject--campaignblock2 .campaignblock2__image {
    margin: 30px 10% 0;
    text-align: center;
  }
  .embeddedobject--campaignblock2 .campaignblock2__image img {
    vertical-align: bottom;
    height: auto;
    width: 100%;
    max-height: 100%;
  }
}