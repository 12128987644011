.tiredetails__readyforcooperperformance {
  font-size: 32px;
  line-height: 36px;
}
@media screen and (max-width: 768px) {
  .tiredetails__readyforcooperperformance {
    display: none;
  }
}

ul.tiredetails__productlinks {
  margin: 25px 0 20px;
  padding: 0 30px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 768px) {
  ul.tiredetails__productlinks {
    display: none;
  }
}

ul.tiredetails__productlinks li {
  list-style: none;
  margin: 0;
  padding: 10px 0;
  font-size: 90%;
}

ul.tiredetails__productlinks li span.gt_red {
  margin-right: 20px;
}

ul.tiredetails__productlinks li + li {
  border-top: 1px solid #e6e7ea;
}

ul.tiredetails__productlinks a {
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: #001d60;
}
ul.tiredetails__productlinks a:after {
  content: "";
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 0;
  height: 3px;
  display: block;
  background: #EB0328;
  transition: width 0.3s;
}
html.non-touch ul.tiredetails__productlinks a:hover {
  cursor: pointer;
}
html.non-touch ul.tiredetails__productlinks a:hover:after {
  width: 100%;
}
ul.tiredetails__productlinks a:after {
  top: calc(100% + 3px);
}

.tiredetails__speaktoanexpert {
  min-width: 400px !important;
}
@media screen and (max-width: 768px) {
  .tiredetails__speaktoanexpert {
    display: none;
  }
}

.tiredetails__speaktoanexpert__mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .tiredetails__speaktoanexpert__mobile {
    display: inline-flex;
    width: 83.3333333333vw;
    margin: 0 8.3333333333vw 20px;
  }
}