.photoalbum__mobilenav {
  display: none;
}
@media (max-width: 768px) {
  .photoalbum .swiper-button-next,
  .photoalbum .swiper-button-prev {
    display: none;
  }
  html.cooper .photoalbum__mobilenav, html.avon .photoalbum__mobilenav {
    display: inline-block;
    background-image: none;
    width: auto;
  }
  html.cooper .photoalbum__mobilenav--next, html.avon .photoalbum__mobilenav--next {
    right: 0;
  }
  html.cooper .photoalbum__mobilenav--prev, html.avon .photoalbum__mobilenav--prev {
    left: 0;
    transform: scaleX(-1);
  }
}
.photoalbum .contentwrapper {
  position: relative;
}
html.page-press .photoalbum .contentwrapper {
  max-width: 675px;
}
@media (max-width: 1200px) {
  html.page-press .photoalbum .contentwrapper {
    max-width: 50vw;
  }
}
@media (max-width: 768px) {
  html.page-press .photoalbum .contentwrapper {
    max-width: 100%;
  }
}
.photoalbum__galleries {
  position: relative;
}
.photoalbum__item::before, .photoalbum__item::after {
  content: "";
  display: table;
  clear: both;
  width: 1px;
  margin-left: -1px;
}
.photoalbum__item::before {
  float: left;
  padding-bottom: 56.25%;
}
.photoalbum__image {
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                           */
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.photoalbum__gallery-bottom {
  position: absolute;
  bottom: -80px;
  left: 0;
  right: 0;
  height: 150px;
  z-index: 10;
}
.photoalbum__thumbs {
  position: absolute;
  bottom: 0;
  right: 24px;
  width: 622px;
  height: 150px;
}
html.page-press .photoalbum__thumbs {
  width: 300px;
}
.photoalbum__thumbitem {
  width: 200px;
  height: 150px;
  background: #E2E2E2;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.photoalbum__navigation {
  position: absolute;
  right: 646px;
  bottom: 0;
  display: flex;
  margin-right: 38px;
}
html.page-press .photoalbum__navigation {
  right: 324px;
}
html[dir=rtl] .photoalbum__navigation .photoalbum__prev {
  order: 2;
}
html[dir=rtl] .photoalbum__navigation .photoalbum__next {
  order: 1;
}
.photoalbum__next, .photoalbum__prev {
  cursor: pointer;
  transition: opacity 0.3s;
}
.photoalbum__next,
.photoalbum__next > svg, .photoalbum__prev,
.photoalbum__prev > svg {
  display: block;
}
.photoalbum__next.disabled, .photoalbum__prev.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.photoalbum__next {
  transform: scaleX(-1);
  margin-left: 22px;
}
.photoalbum .swiper-slide {
  height: auto;
}
@media (max-width: 1200px) {
  html.page-press .photoalbum .photoalbum__thumbs {
    display: none;
  }
  html.page-press .photoalbum .photoalbum__navigation {
    right: 0;
    margin-right: 0;
  }
}
html.avon .photoalbum #Oval-7 {
  fill: rgb(235, 5, 40);
}
@media (max-width: 768px) {
  .photoalbum__gallery-bottom {
    display: none;
  }
  html.cooper .photoalbum__mobilenav, html.avon .photoalbum__mobilenav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  html.cooper .photoalbum__mobilenav--prev, html.avon .photoalbum__mobilenav--prev {
    transform: translateY(-50%) scaleX(-1);
  }
}