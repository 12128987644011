.p-search .pageheader {
  margin-bottom: 0;
}
.p-search .crumblepath {
  margin-top: 40px;
}
.p-search .p-searchform {
  position: relative;
  background: #F4F5F7;
  padding: 16px 0 30px;
}
.p-search .p-searchform__searchcontainer {
  display: flex;
}
.p-search .p-searchform #searchform-search {
  flex: 1;
  margin-right: 12px;
}
html[dir=rtl] .p-search .p-searchform #searchform-search {
  margin-left: 12px;
  margin-right: 0;
}
.p-search .p-searchform .wh-form {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .p-search .p-searchform__searchcontainer {
    display: block;
  }
}
.p-search .p-resulttitle {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 100%;
  margin: 45px 0 65px;
}
html.cooper .p-search .p-resulttitle {
  color: #001d60;
}
html.avon .p-search .p-resulttitle {
  color: #000;
}
.p-search .p-results__row {
  display: flex;
  margin: 0 0 40px;
}
.p-search .p-results--tires {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  margin-left: -30px;
  margin-bottom: 0;
}
.p-search .p-results--tires .p-results__tire {
  position: relative;
  text-decoration: none;
  border-left: 30px solid #fff;
  display: block;
  flex: 1 1 100%;
  max-width: 25%;
  margin: 0 0 30px;
  position: relative;
  overflow: hidden;
}
.p-search .p-results--tires .p-results__tire::before {
  content: "";
  position: relative;
  z-index: -1;
  display: block;
  padding-top: 120%;
  min-height: 325px;
}
html.cooper .p-search .p-results--tires .p-results__tire::before {
  background-image: linear-gradient(212deg, #001d60 30%, #000309 100%);
}
html.avon .p-search .p-results--tires .p-results__tire::before {
  background-color: #dbdde2;
}
.p-search .p-results--tires .p-results__tire .p-results__image {
  display: block;
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  height: 300px;
  transform: scale(0.9, 0.9);
  transition: transform 0.25s;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  background-position: 5px 40px !important;
}
.p-search .p-results--tires .p-results__tire:hover .p-results__title {
  text-decoration: none;
}
.p-search .p-results--tires .p-results__tire:hover .p-results__image {
  transform: scale(1, 1);
}
.p-search .p-results--tires .p-results__tire .p-results__title {
  display: block;
  position: absolute;
  font-weight: bold;
  color: #fff;
  top: 0;
  left: 0;
  padding: 15px;
  width: 100%;
}
html.avon .p-search .p-results--tires .p-results__tire .p-results__title {
  color: #000;
  font-family: ITC Avant Garde Gothic, Helvetica Neue, Helvetica, Arial, sans-serif;
}
@media (max-width: 800px) {
  .p-search .p-results--tires .p-results__tire {
    max-width: 33.3333%;
  }
}
@media (max-width: 550px) {
  .p-search .p-results--tires {
    margin-left: -20px;
  }
  .p-search .p-results--tires .p-results__tire {
    border-left-width: 20px;
    max-width: 50%;
    margin-bottom: 20px;
  }
}
@media (max-width: 330px) {
  .p-search .p-results--tires {
    margin-left: 0;
    display: block;
  }
  .p-search .p-results--tires .p-results__tire {
    border-left-width: 0;
    max-width: 100%;
    margin-bottom: 15px;
  }
}
.p-search .p-results__image {
  width: 270px;
  height: 225px;
  display: block;
}
.p-search .p-results__description {
  flex: 1;
  padding-left: 30px;
}
html[dir=rtl] .p-search .p-results__description {
  padding-right: 30px;
  padding-left: 0;
}
.p-search .p-results__description .match {
  font-weight: bold;
}
.p-search .p-results__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 100%;
  text-decoration: none;
  margin: 0 0 15px;
  display: inline-block;
}
html.cooper .p-search .p-results__title {
  color: #001d60;
}
html.avon .p-search .p-results__title {
  color: #000;
}
html.non-touch .p-search .p-results__title:hover {
  text-decoration: underline;
}
.p-search .p-results__readmore {
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
}
html.cooper .p-search .p-results__readmore {
  color: #001d60;
}
html.avon .p-search .p-results__readmore {
  color: #000;
}
html.non-touch .p-search .p-results__readmore:hover {
  text-decoration: underline;
}
.p-search .p-results__readmoregt {
  margin-left: 5px;
}
html[dir=rtl] .p-search .p-results__readmoregt {
  margin-right: 5px;
  margin-left: 0;
}