@import url("https://cdn.fonts.net/t/1.css?apiType=css&projectid=b702a62c-8442-4fb3-b6dd-65718be42589");
/***********************************************

  Fonts for Cooper

***********************************************/
@font-face {
  font-family: "Trade Gothic LT";
  font-style: normal;
  src: url("fonts/76d6047c-2810-4e5d-852b-96743d198a08.eot?#iefix");
  src: url("fonts/76d6047c-2810-4e5d-852b-96743d198a08.eot?#iefix") format("eot"), url("fonts/43e1c20e-433c-4888-aff7-cfcd6ce88e94.woff2") format("woff2"), url("fonts/d58195f3-0c43-4827-9606-19e4a4b98f27.woff") format("woff"), url("fonts/3f135591-9d3d-4c3b-957d-1f07c5659bc3.ttf") format("truetype"), url("fonts/b2d3d4c3-701a-4f9b-afdc-41c9f594ca53.svg#b2d3d4c3-701a-4f9b-afdc-41c9f594ca53") format("svg");
}
@font-face {
  font-family: "Trade Gothic LT";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/ffd1dd9b-5980-474a-b3a6-70c2cc1566c0.eot?#iefix");
  src: url("fonts/ffd1dd9b-5980-474a-b3a6-70c2cc1566c0.eot?#iefix") format("eot"), url("fonts/298ebcc3-9316-4b07-b25c-56ce088da4c2.woff2") format("woff2"), url("fonts/a6366706-6a39-4314-b667-b0253cfcadc4.woff") format("woff"), url("fonts/dfb6db6c-10f0-49e0-a2da-55c6bf8098d9.ttf") format("truetype"), url("fonts/678d5765-627a-4106-90dd-7b87c4cf16ca.svg#678d5765-627a-4106-90dd-7b87c4cf16ca") format("svg");
  src: url("https://cdn.fonts.net/kit/b702a62c-8442-4fb3-b6dd-65718be42589/TradeGothic/TradeGothicBoldCondensed20_normal_condensed.woff2") format("woff2"), url("https://cdn.fonts.net/kit/b702a62c-8442-4fb3-b6dd-65718be42589/TradeGothic/TradeGothicBoldCondensed20_normal_condensed.woff") format("woff");
}
@font-face {
  font-family: "Rift";
  src: url("fonts/rift-bold.woff2") format("woff2"), url("fonts/rift-bold.woff") format("woff");
}
/***********************************************

  Fonts for Avon

***********************************************/
@font-face {
  font-family: "ITC Avant Garde Gothic";
  font-style: normal;
  font-weight: 300;
  src: url("fonts/07413afc-2280-4cfc-8860-184839a93fa4.eot?#iefix");
  src: url("fonts/07413afc-2280-4cfc-8860-184839a93fa4.eot?#iefix") format("eot"), url("fonts/6f1146c0-9c0b-4949-866d-9affc396531f.woff2") format("woff2"), url("fonts/2c50f982-bb73-4891-8d0e-587c1136c2e5.woff") format("woff"), url("fonts/abf065fe-a1ab-4013-8c49-45ce7f8ac828.ttf") format("truetype"), url("fonts/33f16cdf-c09a-4344-89ce-e17a5ecf4664.svg#33f16cdf-c09a-4344-89ce-e17a5ecf4664") format("svg");
}
@font-face {
  font-family: "ITC Avant Garde Gothic";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/7882e98f-91dc-4562-9ae7-89142461ea7d.eot?#iefix");
  src: url("fonts/7882e98f-91dc-4562-9ae7-89142461ea7d.eot?#iefix") format("eot"), url("fonts/4cadb55f-822a-4a35-8918-becfc5a866a3.woff2") format("woff2"), url("fonts/af007af5-dcfa-465a-a278-47ab3eb9a589.woff") format("woff"), url("fonts/9a9365bb-bc37-4768-8d00-df0bdf405665.ttf") format("truetype"), url("fonts/4443cc42-374b-40e5-968c-b29f549f0835.svg#4443cc42-374b-40e5-968c-b29f549f0835") format("svg");
}
@font-face {
  font-family: "ITC Avant Garde Gothic W01BkCn";
  src: url("fonts/27ddd406-3391-44b8-ad25-1f09b24ead8a.eot?#iefix");
  src: url("fonts/27ddd406-3391-44b8-ad25-1f09b24ead8a.eot?#iefix") format("eot"), url("fonts/735440fb-3c21-44c6-a8fc-d881eb1f0ee0.woff2") format("woff2"), url("fonts/13f5a0c5-43bb-47aa-99be-84958d7aaedf.woff") format("woff"), url("fonts/7f1dd606-d478-4b83-86d3-5e059a1a5165.ttf") format("truetype"), url("fonts/7dd5b14f-9d64-403e-98cd-3b24999e6754.svg#7dd5b14f-9d64-403e-98cd-3b24999e6754") format("svg");
}
@font-face {
  font-family: "ITC Avant Garde Gothic W01BdCn";
  src: url("fonts/f16377dc-5142-4e74-8583-86ada1a03483.eot?#iefix");
  src: url("fonts/f16377dc-5142-4e74-8583-86ada1a03483.eot?#iefix") format("eot"), url("fonts/7a4aeff8-d212-4135-86a0-fef9ad776892.woff2") format("woff2"), url("fonts/5e636559-5992-4c97-a50a-a8f46570feba.woff") format("woff"), url("fonts/b9c39b69-c596-4e97-8f56-bd7a9716d7e1.ttf") format("truetype"), url("fonts/8fd47cd9-b863-4716-b093-aae810bd6681.svg#8fd47cd9-b863-4716-b093-aae810bd6681") format("svg");
}
@inport "vars";
body {
  font-size: 18px;
  line-height: 30px;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.cooper body {
  color: #5C666F;
}
html.avon body {
  color: #000;
}
html.cooper body strong,
html.cooper body b {
  color: #001d60;
}
html.avon body {
  font-size: 16px;
  line-height: 30px;
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

html.cooper h1,
html.cooper h2,
html.cooper h3,
html.cooper h4,
html.cooper h5,
html.cooper .intro {
  color: #001d60;
}
html.avon h1,
html.avon h2,
html.avon h3,
html.avon h4,
html.avon h5,
html.avon .intro {
  color: #000;
}

html.cooper h1,
html.cooper h2,
html.cooper h3 {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

h4,
h5 {
  font-weight: 700;
}

h1 {
  font-size: 100px;
  line-height: 90px;
  margin: 1.875rem auto 3.125rem;
}
html.avon h1 {
  font-size: 72px;
  line-height: 70px;
}
@media (max-width: 576px) {
  html.avon h1 {
    font-size: 48px;
    line-height: 46px;
  }
}

h2 {
  font-size: 72px;
  line-height: 80px;
  margin: 1.875rem auto 2.5rem;
}
html.avon h2 {
  font-size: 30px;
  line-height: 35px;
}

h3 {
  font-size: 36px;
  line-height: 40px;
  margin: 1.875rem auto 0.9375rem;
}
html.avon h3 {
  font-size: 20px;
  line-height: 25px;
}

.dealerintro > p,
p.intro {
  font-size: 40px;
  line-height: 50px;
}
html.avon .dealerintro > p,
html.avon p.intro {
  font-size: 20px;
  line-height: 25px;
  color: #ED0000;
}

html.cooper a {
  color: #5C666F;
}
html.avon a {
  color: #000;
}

html.cooper .wh-rtd > h1.heading1 > h1.heading1_centered,
html.cooper .wh-rtd > h2.heading2 {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.cooper html.cooper .wh-rtd > h1.heading1 > h1.heading1_centered,
html.cooper html.cooper .wh-rtd > h2.heading2 {
  color: #001d60;
}
html.avon html.cooper .wh-rtd > h1.heading1 > h1.heading1_centered,
html.avon html.cooper .wh-rtd > h2.heading2 {
  color: #000;
}
html.cooper .wh-rtd > h1.heading1,
html.cooper .wh-rtd > h1.heading1_centered {
  font-size: 100px;
  line-height: 100%;
}
@media (max-width: 576px) {
  html.cooper .wh-rtd > h1.heading1,
  html.cooper .wh-rtd > h1.heading1_centered {
    font-size: 50px;
    line-height: 50px;
  }
}
html.cooper .wh-rtd > h2.heading2 {
  font-size: 36px;
  line-height: 30px;
}
html.cooper .wh-rtd > p.intro {
  font-size: 40px;
  line-height: 50px;
}
@media (max-width: 576px) {
  html.cooper .wh-rtd > p.intro {
    font-size: 22px;
    line-height: 30px;
  }
}
html.cooper html.cooper .wh-rtd > p.normal {
  color: #5C666F;
}
html.avon html.cooper .wh-rtd > p.normal {
  color: #000;
}
.wh-rtd--main > h1.heading1,
.wh-rtd--main > h1.heading1_centered,
.wh-rtd--main > h2.heading2,
.wh-rtd--main > p.normal,
.wh-rtd--main > p.intro,
.wh-rtd--main > ul.unordered,
.wh-rtd--main > ol.ordered,
.wh-rtd--main > .wh-rtd__tablewrap,
.wh-rtd--main > .wh-video {
  text-align: left;
  max-width: 770px;
  padding: 0 25px;
}
html[dir=rtl] .wh-rtd--main > h1.heading1,
html[dir=rtl] .wh-rtd--main > h1.heading1_centered,
html[dir=rtl] .wh-rtd--main > h2.heading2,
html[dir=rtl] .wh-rtd--main > p.normal,
html[dir=rtl] .wh-rtd--main > p.intro,
html[dir=rtl] .wh-rtd--main > ul.unordered,
html[dir=rtl] .wh-rtd--main > ol.ordered,
html[dir=rtl] .wh-rtd--main > .wh-rtd__tablewrap,
html[dir=rtl] .wh-rtd--main > .wh-video {
  text-align: right;
}
.wh-rtd--main > h1.heading1_centered {
  text-align: center;
}
.wh-rtd--main > ul.unordered,
.wh-rtd--main > ol.ordered {
  padding-left: 2.3em;
}
html.page-home .wh-rtd--main > h1.heading1,
html.page-home .wh-rtd--main > h1.heading1_centered,
html.page-home .wh-rtd--main > h2.heading2 {
  text-align: center;
  max-width: 1170px;
}
.wh-rtd--main > h1.heading1,
.wh-rtd--main > h1.heading1_centered,
.wh-rtd--main > h2.heading2,
.wh-rtd--main > p.normal,
.wh-rtd--main > ul.unordered,
.wh-rtd--main > ol.ordered,
.wh-rtd--main > .wh-rtd__tablewrap,
.wh-rtd--main > .wh-video {
  margin: 0.625rem auto 0.9375rem;
}
.wh-rtd--main > p.intro {
  margin: 0.625rem auto 1.875rem;
}
.wh-rtd--main > .embeddedobject--button {
  text-align: center;
}
.wh-rtd--main > .wh-video > iframe {
  width: calc(100% - 50px) !important;
}
html.avon .wh-rtd--main .wh-rtd__tablewrap {
  overflow-x: auto;
  padding-bottom: 20px;
}
html.avon .wh-rtd--main .wh-rtd__tablewrap .wh-rtd__table {
  width: 100%;
}
html.avon .wh-rtd--main .wh-rtd__tablewrap .wh-rtd__table td, html.avon .wh-rtd--main .wh-rtd__tablewrap .wh-rtd__table th {
  vertical-align: top;
  text-align: left;
  padding-right: 10px;
  line-height: 140%;
}
html.avon .wh-rtd--main .wh-rtd__tablewrap .wh-rtd__table .wh-rtd--hasrowheader th {
  font-weight: normal;
  color: #ED0000;
  border-bottom: 1px solid #000;
}
html.avon .wh-rtd--main .wh-rtd__tablewrap .wh-rtd__table td {
  border-bottom: 1px solid #000;
}
html.avon .wh-rtd--main .wh-rtd__tablewrap .wh-rtd__table .wh-rtd--hascolheader > * {
  font-size: 12px;
  font-weight: normal;
  border-bottom: 4px solid #000;
}

ul,
ol {
  padding-left: 1em;
}
html[dir=rtl] ul,
html[dir=rtl] ol {
  padding-left: 0;
  padding-right: 1em;
}

.wh-video {
  overflow-x: hidden;
}

.swal2-content {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.cooper .swal2-content {
  color: #5C666F;
}
html.avon .swal2-content {
  color: #000;
}

.featurelabel {
  display: inline-block;
  background: #f2f3f7;
  border-radius: 20px;
  padding: 5px 20px;
  margin: 0 5px 10px;
  font-weight: bold;
  color: #001d60;
}
html.avon .featurelabel {
  color: #000;
}