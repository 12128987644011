.promotions__tirepromotion {
  background-color: #000;
  padding: 0 20px;
  height: 65px;
  text-align: center;
  pointer-events: none;
  overflow: hidden;
}

.promotions__tirepromotion__centered {
  position: relative;
  z-index: 0;
  margin: 17px auto 0;
  height: 100%;
}

.promotions__tirepromotion__slogan {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  display: inline-block;
}

html .promotions__tirepromotion .promotions__tirepromotion__button {
  line-height: 65px;
  pointer-events: all;
  font-size: 12px;
  font-weight: bold;
  margin-left: 25px;
  position: relative;
  top: -5px;
}

html.avon .promotions__tirepromotion .promotions__tirepromotion__button {
  height: 60%;
  z-index: -1;
  padding: 0 40px;
}
html.avon .promotions__tirepromotion .promotions__tirepromotion__button > span {
  min-width: 0;
}
html.avon .promotions__tirepromotion__button__l1 {
  position: absolute;
  z-index: -4;
  top: -30px;
  bottom: -30px;
  left: -90px;
  right: -90px;
  background: #111111;
}
html.avon .promotions__tirepromotion__button__l2 {
  position: absolute;
  z-index: -3;
  top: -30px;
  bottom: -30px;
  left: -60px;
  right: -60px;
  background: #242424;
}
html.avon .promotions__tirepromotion__button__l3 {
  position: absolute;
  z-index: -2;
  top: -30px;
  bottom: -30px;
  left: -30px;
  right: -30px;
  background: #353535;
}
html.avon .promotions__tirepromotion__button__l4 {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ed0000;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
html.avon .promotions__tirepromotion .promotions__tirepromotion__button:hover .promotions__tirepromotion__button__l4 {
  background-color: #ffffff;
}

@media (max-width: 770px) {
  .promotions__tirepromotion {
    height: auto;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .promotions__tirepromotion__slogan {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  html .promotions__tirepromotion .promotions__tirepromotion__button {
    line-height: 25px;
    margin-left: 0;
  }
  .promotions__tirepromotion .promotions__tirepromotion__button > span {
    font-size: 15px;
  }
  html.avon .promotions__tirepromotion__button__l1 {
    display: none;
  }
  html.avon .promotions__tirepromotion__button__l2 {
    top: -20px;
    bottom: -20px;
    left: -40px;
    right: -40px;
  }
  html.avon .promotions__tirepromotion__button__l3 {
    top: -10px;
    bottom: -10px;
    left: -20px;
    right: -20px;
  }
}
.tiredetailsdialog {
  padding: 35px 25px;
  min-width: 320px;
  max-width: 340px;
}
.tiredetailsdialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tiredetailsdialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.tiredetailsdialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.tiredetailsdialog__modalbg {
  background-color: rgba(0, 0, 0, 0);
  z-index: 10000; /* on top of header */
  padding-top: 20px;
  padding-bottom: 20px;
}
.tiredetailsdialog__holder {
  background: white;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
}

.p-specs__alltips__header {
  color: #001d60;
  font-size: 30px;
  padding-bottom: 20px;
  padding-right: 20px;
}
html.cooper .p-specs__alltips__header {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-specs__alltips__header {
  color: #ED0000;
}
.p-specs__alltips .close {
  position: absolute;
  right: 15px;
  top: 30px;
  font-size: 20px;
  cursor: pointer;
  color: #001d60;
}
html.avon .p-specs__alltips .close {
  color: #ED0000;
}
.p-specs__alltips .close span + span {
  margin-left: 5px;
}
.p-specs__alltips__line {
  display: flex;
  flex-flow: row;
  line-height: 15px;
  margin-top: 15px;
  color: #5C666F;
}
.p-specs__alltips__line .title {
  font-size: 13px;
  flex: none;
  padding-right: 15px;
  font-weight: bold;
  width: 100px;
}
.p-specs__alltips__line .description {
  font-size: 12px;
}

.p-tiredetails .pageheader {
  margin-bottom: 0;
}
.p-tiredetails .p-intro {
  font-size: 24px;
  line-height: 100%;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F5F7;
  vertical-align: middle;
  margin: 0 0 50px;
}
html.cooper .p-tiredetails .p-intro {
  color: #001d60;
}
html.avon .p-tiredetails .p-intro {
  color: #000;
}
html.avon .p-tiredetails .p-intro {
  background: transparent;
  color: #000000;
  position: relative;
  top: -82px;
  margin-bottom: 0;
}
.p-tiredetails .p-path {
  margin: 0 0 30px;
}
html.avon .p-tiredetails .p-path {
  margin: 0;
}
.p-tiredetails .p-info-container {
  display: flex;
  width: 100%;
  margin: 0 0 3.5rem;
}
.p-tiredetails .p-info-descr {
  width: 400px;
}
.p-tiredetails .p-info-images {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}
.p-tiredetails .p-info-images__image {
  cursor: zoom-in;
}
.p-tiredetails .p-info-images__enlarge-container {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: opacity 0.4s;
}
.p-tiredetails .p-info-images__enlarge {
  background: rgba(255, 255, 255, 0.85);
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 65px;
  padding: 0 40px;
}
.p-tiredetails .p-info-images__enlarge-icon {
  margin-right: 5px;
}
.p-tiredetails .p-info-images__enlarge-icon svg {
  display: block;
  width: 44px;
}
.p-tiredetails .p-info-images__enlarge-text {
  font-size: 32px;
  line-height: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
html.cooper .p-tiredetails .p-info-images__enlarge-text {
  color: #001d60;
}
html.avon .p-tiredetails .p-info-images__enlarge-text {
  color: #000;
}
.p-tiredetails .p-info-images:hover .p-info-images__enlarge-container {
  opacity: 1;
}
.p-tiredetails .p-reviews {
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin: 0 0 35px;
}
.p-tiredetails .p-reviews__testlabel {
  margin-right: 10px;
}
.p-tiredetails .p-reviews__testlabel img {
  display: block;
  height: 56px;
  width: auto;
}
.p-tiredetails .p-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 72px;
  line-height: 70px;
  margin: 0 0 2.1875rem;
}
html.cooper .p-tiredetails .p-title {
  color: #001d60;
}
html.avon .p-tiredetails .p-title {
  color: #000;
}
html.avon .p-tiredetails .p-title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.p-tiredetails .p-description {
  width: 100%;
  max-width: 310px;
  margin: 0 0 20px;
}
.p-tiredetails .p-availablesizes {
  display: inline-block;
  margin: 0 0 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
}
html.cooper .p-tiredetails .p-availablesizes {
  color: #001d60;
}
html.avon .p-tiredetails .p-availablesizes {
  color: #000;
}
.p-tiredetails .p-availablesizes__icon {
  margin-left: 10px;
  position: relative;
  top: 1px;
}
.p-tiredetails .p-availablesizes__icon .svg-gt {
  stroke: #001d60;
}
html.cooper .p-tiredetails .p-availablesizes {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-tiredetails .p-availablesizes {
  color: #EB0328;
}
html.avon .p-tiredetails .p-availablesizes__icon {
  top: 2px;
}
html.avon .p-tiredetails .p-availablesizes__icon .svg-gt {
  stroke: #EB0328;
}
.p-tiredetails .p-mobile-carousel {
  display: none;
}
.p-tiredetails .p-find-store__form, .p-tiredetails .p-find-store__results {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
}
.p-tiredetails .p-find-store__results {
  display: none;
}
.p-tiredetails .p-find-store__form {
  margin-bottom: 15px;
}
.p-tiredetails .p-find-store__title {
  height: 3.125rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-left: 70px;
}
html.cooper .p-tiredetails .p-find-store__title {
  background: #001d60;
  color: #fff;
}
html[dir=rtl] .p-tiredetails .p-find-store__title {
  padding-left: 0;
  padding-right: 70px;
}
.p-tiredetails .p-find-store__input-container {
  position: relative;
}
.p-tiredetails .p-find-store .p-find-store__input {
  box-shadow: none;
  width: 100%;
  padding-left: 70px;
  padding-right: 70px;
  border: 0;
  height: 3.125rem;
  outline: none;
}
.p-tiredetails .p-find-store__submit-button {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
}
html[dir=rtl] .p-tiredetails .p-find-store__submit-button {
  right: auto;
  left: 30px;
}
.p-tiredetails .p-find-store__result, .p-tiredetails .p-find-store__more {
  transition: background 0.3s;
}
.p-tiredetails .p-find-store__result:hover, .p-tiredetails .p-find-store__more:hover {
  background: #f2f2f2;
}
.p-tiredetails .p-find-store__result {
  display: block;
  text-decoration: none;
  padding: 1.5625rem;
  border-bottom: 1px solid #979797;
}
.p-tiredetails .p-find-store__name-distance {
  display: flex;
  margin: 0 0 1.25rem;
}
.p-tiredetails .p-find-store__name, .p-tiredetails .p-find-store__distance {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 100%;
}
.p-tiredetails .p-find-store__name {
  flex: 1;
  color: #5C666F;
}
html.cooper .p-tiredetails .p-find-store__distance {
  color: #001d60;
}
html.avon .p-tiredetails .p-find-store__distance {
  color: #000;
}
.p-tiredetails .p-find-store__address {
  font-size: 16px;
  line-height: 30px;
}
.p-tiredetails .p-find-store__more {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  text-transform: uppercase;
}
html.cooper .p-tiredetails .p-find-store__more {
  color: #001d60;
}
html.avon .p-tiredetails .p-find-store__more {
  color: #000;
}
.p-tiredetails .p-find-store__more, .p-tiredetails .p-find-store__more:hover {
  text-decoration: none;
}
html.avon .p-tiredetails .p-find-store__title {
  background: #000;
  color: #fff;
}
html.avon .p-tiredetails .p-find-store__name, html.avon .p-tiredetails .p-find-store__distance {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.avon .p-tiredetails .p-find-store__name {
  color: #000;
}
html.avon .p-tiredetails .p-find-store__distance {
  font-weight: normal;
  font-size: 14px;
  color: #ED0000;
}
html.avon .p-tiredetails .p-find-store__more {
  color: #ED0000;
}
.p-tiredetails .p-properties {
  padding: 65px 45px 40px;
  margin: 0 0 6.25rem;
}
.p-tiredetails .p-properties__cols {
  display: flex;
}
.p-tiredetails .p-properties__col {
  width: 50%;
  padding-right: 3%;
}
html[dir=rtl] .p-tiredetails .p-properties__col {
  padding-right: 0;
  padding-left: 3%;
}
.p-tiredetails .p-properties__property {
  display: flex;
}
html.type-motorcycle .p-tiredetails .p-properties__property--quiet-ride {
  display: none;
}
.p-tiredetails .p-properties__value {
  margin: 0 0 30px;
}
.p-tiredetails .p-properties__name {
  padding-right: 20px;
}
html.cooper .p-tiredetails .p-properties__name {
  font-size: 22px;
  line-height: 30px;
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .p-tiredetails .p-properties__name {
  font-size: 16px;
  line-height: 26px;
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
}
html[dir=rtl] .p-tiredetails .p-properties__name {
  padding-left: 20px;
  padding-right: 0;
}
.p-tiredetails .p-properties__score {
  height: 9px;
  width: 130px;
  background: #B2B2B2;
  margin-right: 40px;
  position: relative;
}
html[dir=rtl] .p-tiredetails .p-properties__score {
  margin-left: 40px;
  margin-right: 0;
}
.p-tiredetails .p-properties__score-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #001d60;
}
.p-tiredetails .p-properties__score-bar--0 {
  width: 0;
}
.p-tiredetails .p-properties__score-bar--1 {
  width: 20%;
}
.p-tiredetails .p-properties__score-bar--2 {
  width: 40%;
}
.p-tiredetails .p-properties__score-bar--3 {
  width: 60%;
}
.p-tiredetails .p-properties__score-bar--4 {
  width: 80%;
}
.p-tiredetails .p-properties__score-bar--5 {
  width: 100%;
}
html.avon .p-tiredetails .p-properties__score-bar {
  background: #EB0328;
}
html.cooper .p-tiredetails .p-properties {
  background: #F4F5F7;
}
html.avon .p-tiredetails .p-properties {
  margin-bottom: 0;
  padding-bottom: 60px;
}
.p-tiredetails .p-specs {
  padding: 100px 0;
  position: relative;
}
.p-tiredetails .p-specs__title {
  font-size: 36px;
  line-height: 30px;
  margin: 0 0 2.0625rem;
}
.p-tiredetails .p-specs__subtitle {
  font-size: 28px;
  line-height: 100%;
  margin: 0 0 1.25rem;
}
html.cooper .p-tiredetails .p-specs__subtitle {
  color: #001d60;
}
html.avon .p-tiredetails .p-specs__subtitle {
  color: #000;
}
.p-tiredetails .p-specs__row.hide-by-page-nav {
  display: none;
}
.p-tiredetails .p-specs__pagination {
  margin: 80px 0 0;
}
.p-tiredetails .p-specs__rimsize-container {
  display: flex;
  align-items: center;
  margin: 0 0 2.625rem;
}
.p-tiredetails .p-specs__rimsize-container .wh-form__label {
  margin: 0;
}
.p-tiredetails .p-specs__rimsize-container .wh-form__fields {
  width: 300px;
  margin: 0 0 0 2.1875rem;
}
html[dir=rtl] .p-tiredetails .p-specs__rimsize-container .wh-form__fields {
  margin-left: 0;
  margin-right: 2.1875rem;
}
.p-tiredetails .p-specs__rimsize-container #tiredetails-tiresize {
  text-transform: uppercase;
}
.p-tiredetails .p-specs__table {
  max-width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.p-tiredetails .p-specs__table table {
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 100%;
}
.p-tiredetails .p-specs__table th,
.p-tiredetails .p-specs__table td {
  padding: 0;
}
.p-tiredetails .p-specs__table th {
  font-size: 12px;
  line-height: 100%;
  white-space: nowrap;
  text-align: left;
  border-bottom: 4px solid #001d60;
  padding: 0 0.9375rem 0.625rem 0;
  font-weight: normal;
  vertical-align: top;
}
.p-tiredetails .p-specs__table th.p-specs__table--noborder {
  border-color: transparent;
}
html[dir=rtl] .p-tiredetails .p-specs__table th {
  padding-left: 0.9375rem;
  padding-right: 0;
  text-align: right;
}
.p-tiredetails .p-specs__table td {
  font-size: 16px;
  line-height: 100%;
  border-bottom: 1px solid #979797;
  padding: 1.25rem 5px 1.25rem 0;
}
.p-tiredetails .p-specs__table td:nth-child(1) {
  min-width: 140px;
  padding-right: 1.25rem;
  font-weight: 700;
}
html[dir=rtl] .p-tiredetails .p-specs__table td:nth-child(1) {
  padding-left: 1.25rem;
  padding-right: 0;
}
.p-tiredetails .p-specs__footnoteletter {
  cursor: pointer;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  text-align: center;
  width: 20px;
}
html.avon .p-tiredetails .p-specs__rimsize-container .wh-form__label {
  color: #ED0000;
}
html.avon .p-tiredetails .p-specs__table th:not(.p-specs__table--noborder) {
  border-color: #000;
}
html.avon .p-tiredetails .p-specs__table td:nth-child(1) {
  color: #ED0000;
}
html.avon .p-tiredetails .p-specs .svg-download-small {
  fill: #000;
}
.p-tiredetails .p-specs__footnotepopup {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  padding-top: 45px;
  transform: translateX(calc(40px - 100%));
  transition: opacity 0.3s;
  z-index: -1;
}
.p-tiredetails .p-specs__footnotepopup--show {
  opacity: 1;
  z-index: 0;
}
.p-tiredetails .p-specs__footnotepopup-content {
  width: 400px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}
.p-tiredetails .p-specs__footnotepopup-content p {
  margin: 0;
}
.p-tiredetails .p-specs__footnotepopup-topimg {
  content: "";
  position: absolute;
  top: 23px;
  right: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 22px;
  border-color: transparent transparent #fff;
  z-index: 9999;
}
.p-tiredetails .p-specs__treadpatternpopup {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
}
.p-tiredetails .p-specs__treadpatternpopup--show {
  display: block;
}
.p-tiredetails .p-specs__treadpatternpopup-content {
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  left: 50%;
  padding: 20px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
.p-tiredetails .p-specs__treadpatternpopup-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
html[dir=rtl] .p-tiredetails .p-specs__treadpatternpopup-close {
  left: 20px;
  right: auto;
}
.p-tiredetails .p-specs__treadpatternpopup-close .svg-modal-close {
  stroke: #000000;
}
.p-tiredetails .p-specs__treadpatternpopup .p-specs__table td + td {
  padding-left: 2px;
}
.p-tiredetails .p-tyre-care {
  margin: 0 0 5.625rem;
}
.p-tiredetails .p-tyre-care h3 {
  margin-bottom: 50px;
}
.p-tiredetails .p-fullscreenslider {
  position: relative;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}
.p-tiredetails .p-fullscreenslider.show {
  opacity: 1;
  pointer-events: auto;
}
.p-tiredetails .p-fullscreenslider__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  background-image: linear-gradient(-224deg, #373737 7%, #000 100%);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.31);
}
.p-tiredetails .p-fullscreenslider__image {
  display: block;
  height: 90vh;
}
.p-tiredetails .p-fullscreenslider__close {
  position: absolute;
  top: 4vw;
  right: 4vw;
  z-index: 5;
}
html[dir=rtl] .p-tiredetails .p-fullscreenslider__close {
  left: 4vw;
  right: auto;
}
.p-tiredetails .p-fullscreenslider .swiper-container {
  height: 100%;
}
.p-tiredetails .p-fullscreenslider .swiper-slide {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.p-tiredetails .p-fullscreenslider .swiper-button-prev,
.p-tiredetails .p-fullscreenslider .swiper-button-next {
  background: none;
}
.p-tiredetails .p-fullscreenslider .swiper-button-prev {
  margin-left: 4vw;
}
.p-tiredetails .p-fullscreenslider .swiper-button-next {
  margin-right: 4vw;
  transform: rotate(180deg);
}
.p-tiredetails .p-productvideo .embeddedobject--video {
  margin-top: 0;
  margin-bottom: 0;
}
.p-tiredetails .p-expand__label {
  display: none;
}
html.type-motorsport .p-tiredetails .p-expand--properties {
  display: none;
}
.p-tiredetails .p-expand--specs {
  border-bottom: 1px solid transparent;
}
.p-tiredetails .p-expand--overviewcomments {
  border-bottom: 1px solid transparent;
  margin: 0 0 60px;
}
html.avon .p-tiredetails .p-expand--specs, html.avon .p-tiredetails .p-expand--properties {
  background: #F4F5F7;
}
@media (max-width: 768px) {
  html.avon .p-tiredetails .p-expand--specs, html.avon .p-tiredetails .p-expand--properties {
    background: none;
  }
}
@media (max-width: 768px) {
  .p-tiredetails .contentwrapper {
    padding: 0;
  }
  .p-tiredetails .p-path,
  .p-tiredetails .p-title,
  .p-tiredetails .p-description,
  .p-tiredetails .p-reviews,
  .p-tiredetails .p-find-store,
  .p-tiredetails .p-tyre-care,
  .p-tiredetails .pageheader__contents,
  .p-tiredetails .pageheader__tirelinks,
  .p-tiredetails .p-intro__part,
  .p-tiredetails .p-expand,
  .p-tiredetails .p-availablesizes {
    padding-left: 8.3333333333vw;
    padding-right: 8.3333333333vw;
  }
  .p-tiredetails .crumblepath {
    padding-left: 0;
  }
  .p-tiredetails .p-intro {
    flex-direction: column;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
  }
  .p-tiredetails .p-intro__part {
    font-size: 16px;
    line-height: 20px;
    display: block;
    line-height: 140%;
    text-align: center;
  }
  .p-tiredetails .p-info-descr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .p-tiredetails .p-title {
    order: 1;
    margin-bottom: 10px;
  }
  .p-tiredetails .p-description {
    font-size: 16px;
    line-height: 30px;
    order: 2;
    max-width: initial;
    color: #9B9B9B;
    margin-bottom: 30px;
  }
  .p-tiredetails .p-mobile-carousel {
    display: block;
    position: relative;
    order: 3;
    margin: 0 0 15px;
  }
  .p-tiredetails .p-mobile-carousel__mobilenav {
    display: none;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .p-tiredetails .p-mobile-carousel .swiper-button-next,
  .p-tiredetails .p-mobile-carousel .swiper-button-prev {
    display: none;
  }
  html.cooper .p-tiredetails .p-mobile-carousel__mobilenav, html.avon .p-tiredetails .p-mobile-carousel__mobilenav {
    display: inline-block;
    background-image: none;
    width: auto;
  }
  html.cooper .p-tiredetails .p-mobile-carousel__mobilenav--next, html.avon .p-tiredetails .p-mobile-carousel__mobilenav--next {
    right: 0;
  }
  html.cooper .p-tiredetails .p-mobile-carousel__mobilenav--prev, html.avon .p-tiredetails .p-mobile-carousel__mobilenav--prev {
    left: 0;
    transform: scaleX(-1);
  }
}
@media (max-width: 768px) {
  .p-tiredetails .p-mobile-carousel__image {
    max-width: 100%;
    height: 700px;
    max-height: 50vh;
  }
  .p-tiredetails .p-mobile-carousel__item {
    text-align: center;
    width: 100%;
  }
  .p-tiredetails .p-mobile-carousel__mobilenav {
    position: absolute;
    top: 50%;
  }
  .p-tiredetails .p-mobile-carousel__mobilenav svg .svg-carousel-next-mobile-circle {
    stroke: #E9ECF1;
    opacity: 1;
  }
  .p-tiredetails .p-mobile-carousel__mobilenav svg .svg-carousel-next-mobile-line {
    stroke: #001d60;
  }
  .p-tiredetails .p-reviews,
  .p-tiredetails .p-find-store {
    background: #FAFAFB;
  }
  .p-tiredetails .p-reviews {
    order: 4;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .p-tiredetails .p-reviews__testlabel img {
    margin-bottom: 10px;
  }
  .p-tiredetails .p-find-store {
    order: 5;
    padding-bottom: 30px;
    margin-bottom: 0;
  }
  .p-tiredetails .p-find-store__title {
    padding-left: 8.3333333333vw;
  }
  .p-tiredetails .p-find-store__input {
    font-size: 18px;
    line-height: 100%;
    padding-left: 8.3333333333vw !important;
  }
  .p-tiredetails .p-info-images,
  .p-tiredetails .p-expand__item,
  .p-tiredetails .p-features {
    display: none;
  }
  .p-tiredetails .p-tyre-care {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
  .p-tiredetails .p-expand--last {
    margin-bottom: 45px;
  }
  .p-tiredetails .p-expand--last .p-expand__label {
    border-bottom: 1px solid #E2E2E2;
  }
  .p-tiredetails .p-expand__label {
    font-size: 20px;
    line-height: 100%;
    display: flex;
    height: 80px;
    align-items: center;
    border-top: 1px solid #E2E2E2;
    text-decoration: none;
    position: relative;
  }
  html.cooper .p-tiredetails .p-expand__label {
    color: #001d60;
  }
  html.avon .p-tiredetails .p-expand__label {
    color: #000;
  }
  .p-tiredetails .p-expand__label::after {
    content: "";
    width: 21px;
    height: 13px;
    background: no-repeat url("../../web/img/expand-arrow-down.svg");
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s;
  }
  html[dir=rtl] .p-tiredetails .p-expand__label::after {
    right: auto;
    left: 10px;
  }
  .p-tiredetails .p-expand.show .p-expand__item {
    display: block;
  }
  .p-tiredetails .p-expand.show .p-expand__label::after {
    transform: translateY(-50%) rotateX(-180deg);
  }
  .p-tiredetails .p-properties {
    margin-bottom: 0;
    padding: 20px 0 40px;
  }
  .p-tiredetails .p-properties__cols {
    display: block;
  }
  .p-tiredetails .p-properties__col {
    width: 100%;
    padding-right: 0;
  }
  .p-tiredetails .p-properties__value {
    margin-bottom: 40px;
  }
  .p-tiredetails .p-specs {
    padding-top: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .p-tiredetails .p-specs__title {
    display: none;
  }
}
@media (max-width: 576px) {
  .p-tiredetails .p-title {
    font-size: 50px;
    line-height: 100%;
  }
  .p-tiredetails .p-properties {
    padding-left: 20px;
    padding-right: 20px;
  }
  .p-tiredetails .p-properties__property {
    display: block;
    margin-bottom: 20px;
  }
  .p-tiredetails .p-properties__score {
    width: 100%;
  }
  .p-tiredetails .p-properties__value {
    margin-bottom: 10px;
  }
}

.p-downloadreleaseprompt {
  display: none;
  height: 450px;
  left: calc(50% - 325px);
  padding: 50px 60px;
  position: fixed;
  top: calc(50% - 225px);
  width: 650px;
  z-index: 5;
}
@media (max-width: 576px) {
  .p-downloadreleaseprompt {
    font-size: 18px;
    line-height: 24px;
    bottom: 0;
    height: auto;
    left: 0;
    margin-top: 100px;
    overflow: auto;
    padding: 8.3333333333vw;
    right: 0;
    top: 0;
    width: auto;
  }
}
.p-downloadreleaseprompt--show {
  display: block;
}
.p-downloadreleaseprompt__close {
  position: absolute;
  right: 25px;
  top: 25px;
}
.p-downloadreleaseprompt__close .svg-modal-close {
  stroke: #000000;
}
.p-downloadreleaseprompt .p-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 62px;
  line-height: 60px;
}
html.cooper .p-downloadreleaseprompt .p-title {
  color: #001d60;
}
html.avon .p-downloadreleaseprompt .p-title {
  color: #000;
}
html.avon .p-downloadreleaseprompt .p-title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
@media (max-width: 576px) {
  .p-downloadreleaseprompt .p-title {
    font-size: 30px;
    line-height: 100%;
    padding: 0;
    width: 50%;
  }
}
.p-downloadreleaseprompt .p-downloadreleaseintro {
  max-height: 50%;
  overflow: scroll;
}
.p-downloadreleaseprompt .p-downloadrelease {
  height: auto;
  line-height: 1.25rem;
  margin: 40px 50px 0;
  min-height: 3.125rem;
  padding: 15px 0;
  text-align: center;
}
.p-downloadreleaseprompt::after {
  background: #fff;
  bottom: 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  z-index: -1;
}
.p-downloadreleaseprompt::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -2;
}

.p-specs__coltitle {
  display: inline-block;
  cursor: default;
}

.p-specs__2cols {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .p-specs__2cols {
    display: block;
  }
}

.p-specs__moreinfo {
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s;
}
.p-specs__moreinfo-wrapper {
  flex: none;
  white-space: nowrap;
  text-align: right;
  margin-bottom: 40px;
}
.p-specs__moreinfo .icon {
  margin-left: 15px;
  display: inline-block;
  border: 1px solid #001d60;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  color: #001d60;
  font-size: 20px;
  text-align: center;
  line-height: 25px;
  transition: color 0.3s, background-color 0.3s;
}
html.avon .p-specs__moreinfo .icon {
  border-color: #ED0000;
  color: #ED0000;
}
.p-specs__moreinfo:hover {
  color: #001d60;
}
.p-specs__moreinfo:hover .icon {
  color: #fff;
  background-color: #001d60;
}
html.avon .p-specs__moreinfo:hover {
  color: #ED0000;
}
html.avon .p-specs__moreinfo:hover .icon {
  color: #fff;
  background-color: #ED0000;
}

.p-specs__tooltip {
  height: 0;
  position: relative;
}
.p-specs__tooltip__content {
  display: none;
  padding: 15px 15px 20px;
  white-space: normal;
  font-size: 11px;
  line-height: 120%;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  width: 180px;
}
.p-specs__tooltip__content::before {
  content: "";
  display: inline-block;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  left: 50%;
  bottom: 100%;
  margin-left: -8px;
}

#specs {
  position: relative; /* needed for positioning single tooltips */
}
#specs > .p-specs__tooltip {
  position: absolute;
}
#specs > .p-specs__tooltip > .p-specs__tooltip__content {
  display: block;
}

.p-downloadrelease {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
  display: block;
  line-height: 3.125rem;
  margin: 0 0 30px;
  text-decoration: none;
  height: 3.125rem;
  text-transform: uppercase;
  padding-left: 70px;
}
@media (max-width: 576px) {
  .p-downloadrelease {
    padding-left: 8.3333333333vw;
    padding-right: 8.3333333333vw;
  }
}
html.cooper .p-downloadrelease {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  background: #001d60;
  color: #fff;
}
html.avon .p-downloadrelease {
  background: #000;
  color: #fff;
}

html.tiredetails--singlemaster .tiredetails--hide-if-singlemaster {
  display: none;
}

html.avon.page-tiredetails .pageheader {
  min-height: 400px;
}
html.avon.page-tiredetails .pageheader__avonpath {
  top: -75px;
}
@media screen and (max-width: 768px) {
  html.avon.page-tiredetails .pageheader {
    padding-top: 0;
    min-height: 300px;
  }
  html.avon.page-tiredetails .p-info-container {
    margin-top: 50px;
  }
}