.faq {
  max-width: 1170px;
  margin: 0 auto;
}
.faq__title {
  margin-bottom: 50px;
}
.faq__accordion {
  border-top: 1px solid #E2E2E2;
}
.faq__question {
  font-size: 28px;
  line-height: 100%;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
html.cooper .faq__question {
  color: #001d60;
}
html.avon .faq__question {
  color: #000;
}
.faq .ui-accordion-header {
  background: none;
  border: 0;
  border-bottom: 1px solid #E2E2E2;
  padding: 35px 140px 35px 100px;
  margin: 0 0 10px;
  outline: none;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  position: relative;
}
.faq .ui-accordion-content {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 40px 200px 55px 0;
  border: 0;
  box-sizing: content-box;
}
.faq .ui-accordion-content,
.faq .ui-accordion-content p {
  margin: 0;
}
.faq .ui-accordion-header,
.faq .ui-accordion-content {
  padding-left: 100px;
}
.faq .ui-accordion-header-icon {
  position: absolute;
  top: 50%;
  right: 100px;
  transform: translateY(-50%);
  width: 35px;
  height: 20px;
  background: url("../../web/img/cooper-faq-expand.svg") no-repeat;
  margin: 3px 0 0;
  transition: transform 0.4s;
  transform-origin: 50% 5px;
}
html[dir=rtl] .faq .ui-accordion-header-icon {
  right: auto;
  left: 100px;
}
.faq .ui-accordion-header-active .ui-accordion-header-icon {
  transform: scale(1, -1);
}
html.avon .faq__title {
  font-size: 36px;
  line-height: 100%;
}
html.avon .faq .ui-accordion-header-icon {
  background: url("../../web/img/avon-faq-expand.svg") no-repeat;
}
@media (max-width: 1024px) {
  .faq .ui-accordion-header,
  .faq .ui-accordion-content {
    padding-left: 50px;
    padding-right: 50px;
  }
  .faq .ui-accordion-header-icon {
    right: 50px;
  }
}
@media (max-width: 768px) {
  .faq .ui-accordion-header,
  .faq .ui-accordion-content {
    padding-left: 0;
    padding-right: 0;
  }
  .faq .ui-accordion-header-icon {
    right: 0;
  }
}