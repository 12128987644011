.pageheader {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 210px;
  position: relative;
  margin: 0 0 2.8125rem;
  overflow: hidden;
}
.pageheader--big {
  padding: 100px 0;
  overflow: hidden;
}
.pageheader--small .pageheader__heading {
  font-size: 36px;
  line-height: 100%;
}
.pageheader--tires .pageheader__heading {
  font-size: 36px;
  line-height: 100%;
  color: #001d60;
  text-shadow: none;
}
.pageheader--tires .pageheader__overlay {
  display: block;
}
.pageheader:not(.pageheader--big) {
  justify-content: center;
}
.pageheader__video, .pageheader__contents {
  transition-property: transform;
}
.pageheader__video {
  position: absolute;
  top: 0;
  left: -9999px;
  right: -9999px;
  margin: 0 auto;
  height: 100%;
  /* 1. No object-fit support: */
  /* 2. If supporting object-fit, overriding (1): */
}
@media (min-aspect-ratio: 16/9) {
  .pageheader__video {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .pageheader__video {
    width: 300%;
    left: -100%;
  }
}
@supports (object-fit: cover) {
  .pageheader__video {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.pageheader__contents {
  position: relative;
}
.pageheader__heading, .pageheader__subheading {
  color: #fff;
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
}
.pageheader__heading {
  font-size: 100px;
  line-height: 100%;
  max-width: 100vw;
}
html.cooper .pageheader__heading {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .pageheader__heading {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.pageheader__subheading {
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
  visibility: hidden;
}
.pageheader__subheading.visible {
  visibility: visible;
}
.pageheader__button-container {
  margin: 3.125rem 0 0;
  text-align: center;
}
.pageheader__button {
  visibility: hidden;
}
.pageheader__button.visible {
  visibility: visible;
}
.pageheader__overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
}
html.cooper .pageheader__tirelinks, html.cooper .pageheader__cleartirelink {
  color: #5C666F;
}
html.avon .pageheader__tirelinks, html.avon .pageheader__cleartirelink {
  color: #000;
}
.pageheader__tirelinks {
  text-transform: uppercase;
  text-align: center;
  margin: 0.4375rem 0 0;
}
.pageheader__edittirelink {
  text-decoration: none;
  font-weight: 700;
}
html.cooper .pageheader__edittirelink {
  color: #001d60;
}
html.avon .pageheader__edittirelink {
  color: #000;
}
html.non-touch .pageheader__edittirelink:hover {
  text-decoration: underline;
}
.pageheader__cleartirelink {
  text-decoration: none;
}
html.non-touch .pageheader__cleartirelink:hover {
  text-decoration: underline;
}
html.isie.avon .pageheader--small .pageheader__contents {
  top: 52px;
}
html.avon .pageheader {
  padding-top: 158px;
  padding-bottom: 75px; /* skewed header element */
}
html.avon .pageheader__avonpath {
  content: "";
  display: flex;
  align-items: center;
  position: relative;
  height: 75px;
  background-color: #fff;
  top: -35px;
  right: -100px;
  left: 50%;
  margin-left: -680px;
  transform: skew(-40deg);
  padding: 0 0 0 75px;
}
html.avon .pageheader__avonpath .crumblepath {
  transform: skew(40deg);
}
@media screen and (max-width: 1400px) {
  html.avon .pageheader__avonpath {
    margin-left: -530px;
  }
}
@media screen and (max-width: 1200px) {
  html.avon .pageheader__avonpath {
    left: 0;
    margin-left: 10vw;
    width: 200vw;
  }
}
@media screen and (max-width: 768px) {
  html.avon .pageheader__avonpath {
    display: none;
  }
}
html.avon .pageheader__heading, html.avon .pageheader__tirelinks, html.avon .pageheader__cleartirelink {
  color: #fff;
}
html.avon .pageheader__edittirelink {
  color: #ED0000;
}
html.avon .pageheader__overlay {
  background-color: rgba(0, 0, 0, 0.1);
}
html.avon .pageheader__avonparallax {
  background: #fff;
  position: absolutel;
}
@media screen and (max-width: 1400px) {
  html.avon .pageheader {
    padding-top: 100px;
  }
}
@media screen and (max-width: 768px) {
  html.avon .pageheader {
    padding-bottom: 0;
  }
}
@media (max-width: 768px) {
  .pageheader__heading {
    font-size: 80px;
    line-height: 100%;
  }
}
@media (max-width: 576px) {
  .pageheader {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pageheader__heading {
    font-size: 50px;
    line-height: 100%;
  }
  .pageheader__subheading {
    font-size: 25px;
    line-height: 30px;
  }
  .pageheader__button-container {
    margin-top: 15px;
  }
  .pageheader__button-container--nobutton {
    display: none;
  }
}
html.apply-parallax-header .pageheader {
  z-index: -1;
  position: fixed;
  top: 100px;
  left: 0;
  transition-property: background-position;
}
html.avon.apply-parallax-header .pageheader {
  top: 0;
  margin-bottom: 0;
}
html.avon.apply-parallax-header .pageheader .pageheader__contents {
  padding-bottom: 55px;
}
@media screen and (max-width: 1400px) {
  html.avon.apply-parallax-header .pageheader .pageheader__contents {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

html.cooper.apply-parallax-header .p-contentpage {
  padding-top: 20px;
  background: #fff;
}
html.cooper.apply-parallax-header .footer {
  position: relative;
}

html.avon.apply-parallax-header .contentwrapper--path {
  display: none;
}
html.avon.apply-parallax-header .sitewrapper > .wh-rtd {
  position: relative;
  margin-top: 0;
  top: 0;
}
html.avon.apply-parallax-header .sitewrapper > .wh-rtd > *:first-child,
html.avon.apply-parallax-header .sitewrapper > .wh-rtd > .pageheader__avonpath:first-child + * {
  margin-top: 0;
}
@media (max-width: 768px) {
  html.avon.apply-parallax-header .sitewrapper > .wh-rtd {
    margin-top: 0;
    padding-top: 80px;
    background: #fff;
  }
}
html.avon.apply-parallax-header .p-contentpage {
  background: #fff;
  position: relative;
}
html.avon.apply-parallax-header .p-contentpage:before {
  content: "";
  display: block;
  position: absolute;
  height: 110px;
  background-color: #fff;
  top: -110px;
  right: -100px;
  left: 50%;
  margin-left: -680px;
  transform: skew(-40deg);
  display: none;
}
html.avon.apply-parallax-header .p-contentpage .pageheader:after {
  display: none;
}
html.avon.apply-parallax-header .footer {
  position: relative;
}

html.avon.apply-parallax-header .crumblepath {
  top: 0;
}
html.avon.apply-parallax-header .pageheader__contents {
  top: 0;
}