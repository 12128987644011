.pagehelper {
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 1170px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -12vh);
  z-index: 1000;
  padding: 30px 70px;
}
.pagehelper__logo, .pagehelper__title, .pagehelper__subtitle {
  text-align: center;
}
.pagehelper__logo {
  margin: 0 0 25px;
}
.pagehelper__title {
  font-size: 36px;
  line-height: 30px;
  margin: 0 0 25px;
  color: #001d60;
  font-weight: 700;
}
html.cooper .pagehelper__title {
  color: #001d60;
}
html.avon .pagehelper__title {
  color: #000;
}
.pagehelper__subtitle {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0;
}
html.cooper .pagehelper__subtitle {
  color: #5C666F;
}
html.avon .pagehelper__subtitle {
  color: #000;
}
html.cooper .pagehelper__subtitle {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.cooper .pagehelper__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
html.avon .pagehelper {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  transform: translate(-50%, -25vh);
}
html.avon .pagehelper__logo {
  background: #F4F5F7;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  background-image: url(../web/img/avon-pattern40.png);
  background-repeat: no-repeat;
  background-position: -150px 0;
  background-size: 340px 200px;
}
html.avon .pagehelper__logo img {
  display: block;
}
html.avon .pagehelper__inner {
  padding-left: 70px;
  padding-right: 70px;
}
html.avon .pagehelper__title {
  font-size: 30px;
  line-height: 100%;
}
html.avon .pagehelper__title > span {
  color: #ED0000;
}
html.avon .pagehelper__subtitle {
  color: #9b9b9b;
  font-size: 16px;
  line-height: 100%;
}
@media (max-width: 768px) {
  html.avon .pagehelper {
    transform: translate(0, 0);
  }
  html.avon .pagehelper #dealersearchinput {
    width: 100%;
    margin: 0 auto 20px;
  }
  html.avon .pagehelper__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}
html.page-dealer-details .pagehelper {
  transform: translate(-50%, -80px);
}
@media (max-width: 768px) {
  html.page-dealer-details .pagehelper {
    transform: none;
  }
}
html.avon.page-dealer-details .pagehelper {
  transform: translate(-50%, -240px);
}
@media (max-width: 768px) {
  html.avon.page-dealer-details .pagehelper {
    transform: translate(0, 0);
  }
}
@media (max-width: 768px) {
  .pagehelper {
    position: static;
    transform: none;
    margin: 20px auto 40px;
    width: calc(100% - 2 * 8.3333333333vw);
    padding: 30px 30px 5px;
  }
}