.newsletter .wh-form__error {
  display: none;
}
.newsletter .wh-form__fieldgroup--error input[type=text],
.newsletter .wh-form__fieldgroup--error input[type=email],
.newsletter .wh-form__fieldgroup--error input[type=url],
.newsletter .wh-form__fieldgroup--error input[type=date],
.newsletter .wh-form__fieldgroup--error input[type=search],
.newsletter .wh-form__fieldgroup--error input[type=number],
.newsletter .wh-form__fieldgroup--error textarea {
  border: 1px solid #e00;
}
.newsletter .wh-form__fieldline {
  margin-bottom: 0.3125rem;
}
.newsletter .wh-form__fieldline input[type=text],
.newsletter .wh-form__fieldline input[type=email] {
  height: 3.875rem;
  box-shadow: none;
}
.newsletter .wh-form__fieldline input[type=text]::placeholder,
.newsletter .wh-form__fieldline input[type=email]::placeholder {
  text-transform: initial;
}
.newsletter .wh-form__fieldgroup {
  margin-bottom: 0;
}
.newsletter .button {
  position: absolute;
  bottom: -2.2rem;
  right: 15px;
  z-index: 10;
}
html[dir=rtl] .newsletter .button {
  left: 15px;
  right: 0;
}
.newsletter__sent {
  display: none;
}
.newsletter.sent .newsletter__form-container {
  display: none;
}
.newsletter.sent .newsletter__sent {
  display: block;
}