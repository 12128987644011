.csuggest-values {
  position: absolute;
  z-index: 100000;
  overflow: auto;
}

.csuggest-values {
  margin: 0;
  padding: 0;
}

.csuggest-values li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.csuggest-values {
  background: #fff;
  border: 1px solid #ccc;
  color: #333;
  border-top: 0;
}

.csuggest-values li {
  padding: 9px 10px 7px;
  cursor: pointer;
}

.csuggest-values li:hover {
  background: #eeeeee;
}