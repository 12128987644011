.embeddedobject--site {
  margin: 6vh 0;
}
@media (min-width: 769px) {
  .embeddedobject--photoalbum {
    padding-bottom: 80px;
  }
}
.embeddedobject--fullwidthimage img {
  display: block;
  width: 100vw;
  height: auto;
}
@media (max-width: 768px) {
  .embeddedobject--carousel {
    padding-bottom: 2.2rem;
  }
}