.findoverlay__example-scroll {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  min-height: 100%;
}
.findoverlay__example-value {
  position: absolute;
  transition: top 300ms;
  top: 0;
}
.findoverlay__example-scroll--scrollaway .findoverlay__example-value {
  top: -100%;
}