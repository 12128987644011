.carousel {
  background: #F4F5F7;
  padding: 60px 0 0;
}
.carousel .swiper-button-prev {
  left: -5vw;
  background-image: url("../../web/img/carousel-prev.svg");
}
.carousel .swiper-button-next {
  right: -5vw;
  background-image: url("../../web/img/carousel-next.svg");
}
@media screen and (max-width: 1300px) {
  .carousel .swiper-button-prev {
    left: 10px;
  }
  .carousel .swiper-button-next {
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .carousel .swiper-button-prev {
    left: 5vw;
  }
  .carousel .swiper-button-next {
    right: 5vw;
  }
}
.carousel__mobilenav {
  display: none;
}
@media (max-width: 768px) {
  .carousel .swiper-button-next,
  .carousel .swiper-button-prev {
    display: none;
  }
  html.cooper .carousel__mobilenav, html.avon .carousel__mobilenav {
    display: inline-block;
    background-image: none;
    width: auto;
  }
  html.cooper .carousel__mobilenav--next, html.avon .carousel__mobilenav--next {
    right: 0;
  }
  html.cooper .carousel__mobilenav--prev, html.avon .carousel__mobilenav--prev {
    left: 0;
    transform: scaleX(-1);
  }
}
.carousel .contentwrapper {
  position: relative;
}
.carousel__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.carousel__image {
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                           */
  width: 100%;
  height: auto;
  display: block;
}
.carousel__info {
  position: relative;
}
.carousel__info h2 {
  font-size: 36px;
  line-height: 43px;
  margin: 0 0 30px;
  color: #001D60;
  text-align: left;
}
html[dir=rtl] .carousel__info h2 {
  text-align: right;
}
.carousel__info p {
  font-size: 20px;
  line-height: 25px;
}
html.cooper .carousel__info p {
  color: #5C666F;
}
html.avon .carousel__info p {
  color: #000;
}
.carousel__button {
  position: absolute;
  bottom: -2.2rem;
  left: 0;
}
.carousel__image-container, .carousel__info {
  width: calc(50% - 15px);
}
.carousel .swiper-container {
  overflow: visible;
}
.carousel .swiper-pagination {
  left: calc(50% + 30px / 2);
  bottom: 125px;
}
html[dir=rtl] .carousel .swiper-pagination {
  left: auto;
  right: calc(50% + 30px / 2);
}
.carousel .swiper-pagination-bullet {
  width: 29px;
  height: 29px;
  background: #fff;
  border-radius: 0;
  margin-right: 11px;
  opacity: 1;
}
.carousel .swiper-pagination-bullet-active {
  background-color: #EB0328;
}
.carousel__mobilenav {
  display: none;
}
html.avon .carousel__image-container {
  width: calc(50% - 70px);
}
html.avon .carousel .swiper-pagination {
  display: none;
}
html.avon .carousel .swiper-button-prev, html.avon .carousel .swiper-button-next {
  top: 20%;
}
html.avon .carousel__info {
  position: relative;
  padding-bottom: 500px; /* space for button and checker pattern */
}
html.avon .carousel__checker {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 400px;
  width: 50vw;
  z-index: -1;
}
html.avon .carousel__checker:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -100vw;
  bottom: -1px; /* fix small gap msie */
  left: -30%;
  background: #fff url(../../web/img/avon-pattern-i.png) 0 -75px repeat-x;
  transform: skew(-40deg);
}
html.avon .carousel__button {
  bottom: 400px;
  left: 30px;
  margin-bottom: -2.2rem;
  position: absolute;
}
@media screen and (max-width: 1170px) {
  html.avon .carousel .contentwrapper {
    padding: 0 !important;
  }
  html.avon .carousel__image-container {
    order: 0;
    padding-left: 20px;
    width: calc(100% - 40px);
    margin-top: 0;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1100px) {
  html.avon .carousel .swiper-pagination {
    display: none;
  }
}
@media (max-width: 768px) {
  .carousel {
    background: #E5E5E5;
  }
  .carousel__item {
    flex-direction: column;
  }
  .carousel__image-container {
    width: 100%;
    order: 2;
    margin-top: 25px;
    flex: none;
  }
  .carousel__info {
    width: 100%;
    position: static;
    padding-left: 20px;
    padding-right: 20px;
    flex: none;
  }
  .carousel__button {
    left: 20px;
    right: 20px;
  }
  .carousel .swiper-pagination {
    display: none;
  }
  html.avon .carousel__info {
    padding-bottom: 150px;
  }
  html.avon .carousel__checker {
    display: none;
  }
  html.avon .carousel__button {
    bottom: 50px;
    margin-bottom: 0;
  }
}