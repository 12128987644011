.p-contactinformation__buttons {
  margin-bottom: 80px;
}
.p-contactinformation__buttons .button {
  margin-bottom: 10px;
  min-width: initial;
  width: 100%;
}
.p-contactinformation__map-container {
  position: relative;
  margin-bottom: calc(80px + 2.2rem);
}
.p-contactinformation__map-container .button {
  position: absolute;
  bottom: -2.2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.p-contactinformation__map-container #google-maps-container {
  width: 100%;
  height: 340px;
}
.p-contactinformation__address a {
  text-decoration: none;
}
html.non-touch .p-contactinformation__address a:hover {
  text-decoration: underline;
}
html.avon .p-contactinformation__map-container .button {
  left: 50%;
  margin-left: -160px;
}
@media (max-width: 768px) {
  .p-contactinformation {
    display: flex;
    flex-direction: column;
  }
  .p-contactinformation__buttons {
    display: none;
  }
  .p-contactinformation__map-container {
    order: 2;
    margin-bottom: calc(50px + 2.2rem);
  }
  .p-contactinformation__address-container {
    margin-bottom: 50px;
    order: 1;
  }
  .p-contactinformation__address {
    margin-bottom: 0;
  }
}