.egghunt__egg {
  background: url(egg.svg) no-repeat center center, url(shadow.svg) no-repeat center 106%;
  width: 72px;
  height: 100px;
  cursor: pointer;
  transition: transform 100ms;
  display: inline-block;
}

.egghunt__egg:hover {
  transform: scale(1.2);
}

.egghunt__egg:before {
  text-indent: -10000px;
  content: "Easter egg";
  display: block;
}