.textimagecols__cols {
  display: flex;
  justify-content: space-between;
}
.textimagecols__cols--mirrored .textimagecols__col--text {
  order: 2;
}
.textimagecols__cols--mirrored .textimagecols__title,
.textimagecols__cols--mirrored .textimagecols__text {
  padding-left: 6.25rem;
  padding-right: 0;
}
.textimagecols__cols--valign-top .textimagecols__col--text > *:first-child {
  margin-top: 0 !important;
}
.textimagecols__col {
  width: calc(50% - 15px);
}
.textimagecols__title, .textimagecols__text {
  padding-right: 6.25rem;
}
.textimagecols__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 100%;
  margin: 4.375rem 0 1.5625rem;
}
html.cooper .textimagecols__title {
  color: #001d60;
}
html.avon .textimagecols__title {
  color: #000;
}
html.avon .textimagecols__title {
  font-family: "ITC Avant Garde Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.textimagecols__text {
  font-size: 18px;
  line-height: 30px;
}
html.cooper .textimagecols__text {
  color: #5C666F;
}
html.avon .textimagecols__text {
  color: #000;
}
.textimagecols__image {
  display: block;
  width: 100%;
  height: auto;
}
html.avon .textimagecols .button {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  .textimagecols__title {
    margin-top: 0;
    padding-right: 0;
  }
  .textimagecols__cols {
    flex-direction: column;
  }
  .textimagecols__cols .textimagecols__title,
  .textimagecols__cols .textimagecols__text, .textimagecols__cols--mirrored .textimagecols__title,
  .textimagecols__cols--mirrored .textimagecols__text {
    padding-left: 0;
    padding-right: 0;
  }
  .textimagecols__col {
    width: 100%;
  }
  .textimagecols__col--text {
    order: 5;
    margin-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
  }
}