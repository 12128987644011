.p-contentpage .p-content {
  margin-top: 40px;
}
.p-contentpage .pageheader {
  margin-bottom: 45px;
}
.p-contentpage .p-content > *:last-child {
  margin-bottom: 0;
  padding-bottom: 45px;
}
html.avon .p-contentpage .p-content {
  position: relative;
  top: -40px;
  margin-top: 0;
}
.p-contentpage .p-addthis {
  max-width: 770px;
  margin: 0 auto 0 auto;
  padding: 0 25px 50px;
}
.p-contentpage .p-addthis--embeddedobj {
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .p-contentpage .p-addthis {
    display: none;
  }
}