.emb-homerun {
  max-width: 1170px;
  margin: 0 auto;
}
.emb-homerun__title {
  margin-bottom: 50px;
}
.emb-homerun__jobs {
  border-top: 1px solid #E2E2E2;
}
.emb-homerun__job {
  font-size: 28px;
  line-height: 100%;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  background: none;
  border: 0;
  border-bottom: 1px solid #E2E2E2;
  padding: 35px 50px 35px 25px;
  margin: 0 0 10px;
  outline: none;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
}
html.cooper .emb-homerun__job {
  color: #001d60;
}
html.avon .emb-homerun__job {
  color: #000;
}
html[dir=rtl] .emb-homerun__job {
  padding-right: 25px;
  padding-left: 50px;
}
.emb-homerun__job:hover {
  background: #f2f2f2;
}
.emb-homerun__job::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg) scale(0.8);
  background: url("../../web/img/cooper-faq-expand.svg") no-repeat;
  width: 35px;
  height: 20px;
}
html[dir=rtl] .emb-homerun__job::after {
  right: auto;
  left: 10px;
  transform: translateY(-50%) rotate(-270deg) scale(0.8);
}
.emb-homerun__jobtitle {
  width: 50%;
}
.emb-homerun__type {
  width: 20%;
  font-size: 60%;
  line-height: 100%;
}
.emb-homerun__location {
  width: 30%;
  font-size: 60%;
  line-height: 100%;
}
html.avon .emb-homerun__title {
  font-size: 36px;
  line-height: 100%;
}
@media (max-width: 1024px) {
  .emb-homerun__job {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 768px) {
  .emb-homerun__job {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
  }
  .emb-homerun__job::after {
    display: none;
  }
  .emb-homerun__jobtitle, .emb-homerun__type, .emb-homerun__location {
    width: 100%;
  }
  .emb-homerun__jobtitle, .emb-homerun__type {
    margin-bottom: 15px;
  }
}